import React, { useState, forwardRef, useImperativeHandle, useCallback, useContext } from 'react';
import { debounce } from 'lodash';
import { IDropdownSelectedValue, IexistingUser } from "../../Interfaces/interface";
import { Autosuggest, FormField } from '@amzn/awsui-components-react';
import { minUserLength } from '../../../constants/constants';
import { validateUserAlias, ValidateUserAliasResponse } from 'src/utils/papi-service';

export const UserSearchEditor = forwardRef((props: any, ref: any) => {
    const [value, setValue] = useState(props.value);
    const [selectedUser, setSelectedUser] = useState('');
    const [userOptions, setUserOptions] = useState<IexistingUser[]>();
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);

    const fetchUserDetails = useCallback(debounce(async (user: string) => {
        if (user.length >= minUserLength) {
            setLoadingOptions(true);
            try {
                const response: ValidateUserAliasResponse = await validateUserAlias(user);

                if (!response.isValid || !response.userInfo) {
                    setUserOptions([]);
                    setErrorLoading(true);
                } else {
                    setUserOptions(() => {
                        return [{
                            value: `${response?.userInfo?.firstName} ${response?.userInfo?.lastName} (${response?.userInfo?.login})`
                        }];
                    });
                }
            } catch (error) {
                setErrorLoading(true);
                setUserOptions([]);
            } finally {
                setLoadingOptions(false);
            }
        } else {
            setUserOptions([]);
        }
    }, 500), []);


    const onUserChange = async ({ detail }: IDropdownSelectedValue) => {
        setSelectedUser(detail.value);
        setErrorLoading(false);
        if (detail.value) await fetchUserDetails(detail.value);
    };

    const onUserSelect = async ({ detail }: IDropdownSelectedValue) => {
        setValue(detail.value.split(' (')[0]);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            }
        };
    });
    return (
        <>
            <div>
                <FormField>
                    <Autosuggest
                        id="userAlias"
                        value={selectedUser}
                        options={userOptions}
                        enteredTextLabel={() => ''}
                        ariaLabel="user-search"
                        placeholder="Enter user alias"
                        empty="No user found"
                        onChange={onUserChange}
                        onSelect={onUserSelect}
                        className='auto-suggest'
                        selectedAriaLabel={selectedUser}
                        errorText="Values could not be retrieved"
                        statusType={errorLoading ? "error" : loadingOptions ? "loading" : "finished"}
                    />
                </FormField>
            </div>
        </>
    );
});