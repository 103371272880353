export default class Queries {

    public static readonly getVerions = () => {
        return `query ($verity_rpn_id: Int) {
            get_agm_prog_version(verity_rpn_id: $verity_rpn_id) {
                body {
                    attribute_type
                    attribute_value
                    version_id
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getAttrOrder = () => {
        return `query ($verity_rpn_id: Int) {
            get_agm_prog_att_sort(verity_rpn_id: $verity_rpn_id) {
                body {
                    attribute_type
                    at_concat_order
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly removeVersions = () => {
        return `mutation ($version_id: Int! ) {
          delete_agm_version(version_id: $version_id) {
            body
            error
            statusCode
          }
        }`;
    }

    public static readonly getAssortments = () => {
        return `query ($verity_rpn_id: Int) {
            get_agm_assort(verity_rpn_id: $verity_rpn_id) {
                body {
                  assortment_id
                  channel
                  country
                  marketplace
                  row
                  sku_type
                  version_id
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getUserAccess = () => {
        return `query ($user_name: String) {
            get_user_access_agm(user_name: $user_name, user_type: "PERSON") {
                body {
                    resource {
                      name
                      resources {
                        name
                      }
                    }
                    roles {
                      name
                    }
                  }
                error
                statusCode
              }
        }`;
    }

    public static readonly updateAssortments = () => {
        return `mutation ($user_id: String!, $assortments: [update_agm_version_assort]) {
            update_agm_assort(input: {user_id: $user_id, assortments: $assortments}) {
              body
              error
              lastSucceededIndex
              statusCode
            }
          }`;
    }

    public static readonly removeAssortments = () => {
        return `mutation ($assortment_id: Int! ) {
            remove_agm_assort(assortment_id: $assortment_id) {
              body
              error
              statusCode
            }
          }`;
    }


    public static readonly getSku = () => {
        return `query ($verity_rpn_id: Int) {
            get_agm_sku(verity_rpn_id: $verity_rpn_id) {
              body {
                asin
                assortment_id
                is_deactivated
                pn
                sku_id
                sku_type
                version_id
                additional_marketplaces
              }
              error
              statusCode
            }
          }`;
    }

    public static readonly addSku = () => {
        return `mutation ($input: [AddAgmNewSku]) {
            add_agm_new_sku(input: $input) {
              body
              error
              statusCode
            }
          }
          `;
    }

    public static readonly updateSkuAssortments = () => {
        return `mutation ($sku_id: Int!, $assortment_ids: [Int]!, $action: String!) {
            update_agm_sku_assortments(input: {sku_id: $sku_id, assortment_ids: $assortment_ids , action: $action}) {
              statusCode
              body
              error
              lastSucceededIndex
            }
          }`;
    }

    public static readonly updateSkuVersionType = () => {
      return `mutation ($sku_id: Int!, $version_id: Int!, $sku_type: String!, $assortment_ids: [Int]!) {
          update_agm_sku_version_and_type(input: {sku_id: $sku_id, version_id: $version_id, sku_type: $sku_type, assortment_ids: $assortment_ids}) {
            statusCode
            body
            error
            lastSucceededIndex
          }
        }`;
  }

    public static readonly requestPN = () => {
        return `mutation ($input: RequestPartNumberInput){
            request_pn(input: $input) {
              body {
                part_number
                sku_id
              }
              error
              statusCode
            }
          }`;
    }

    public static readonly deactivateSKU = () => {
        return `mutation ($input: DeactivateAgmSku) {
            deactivate_agm_sku(input: $input) {
              statusCode
              body
              error
              lastSucceededIndex
            }
          }`;
    }

    public static readonly reactivateSKU = () => {
        return `mutation ($input: DeactivateAgmSku) {
            reactivate_agm_sku(input: $input) {
              statusCode
              body
              error
              lastSucceededIndex
            }
          }`;
    }

    public static readonly getMarketPlaces = () => {
        return `query {
            get_marketplace {
              error
              statusCode
              body {
                country
                marketplace
              }
            }
          }
          `;
    }

    public static readonly updateMarketPlace = () => {
        return `mutation ($sku_id: Int, $marketplaces: [UpdateAgmMarketplace]) {
            update_agm_sku_mp(input: {
            sku_id: $sku_id,
            marketplaces: $marketplaces
          }) {
              body
              error
              statusCode
            }
          }`;
    }

    public static readonly getUpdateQuery = (payload: any, api_name: string) => {
        return {
            "query": `mutation {
                ${api_name}(input: ${payload}) {
                    body
                    error
                    statusCode
                }
            }`
        };
    }
}