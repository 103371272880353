import { ColDef, ColGroupDef, ValueGetterParams } from "ag-grid-community";
import PartNumBtnRenderer from "./partNumBtnRenderer";
import DeactivateReactivateBtnRenderer from "./deactivateBtnRenderer";
import { EditSkuAssortmentsBtnRenderer, EditSkuVersionBtnRenderer } from "./editSkuBtnRenderer";
import MarketPlaceRenderer from "./marketPlaceRenderer";
import { concatWithoutDuplicate } from "./skuHelpers";

export const editSKUAssortmentsModalColDefs = (versions: Record<string, Record<string, any>>): (ColDef | ColGroupDef)[] => {
    return [
        {
            headerName: "Product Version",
            field: "product_version",
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            valueGetter: (params: ValueGetterParams) => {
                return versions[params.node?.data.version_id]?.description;
            },
            minWidth: 500
        },
        {
            headerName: "SKU Type",
            field: "sku_type",
            editable: false
        },
        {
            headerName: "Country",
            field: "country",
            editable: false,
            maxWidth:150
        },
        {
            headerName: "ROW",
            field: "row",
            editable: false,
            maxWidth:150
        },
        {
            headerName: "Channel",
            field: "channel",
            editable: false,
            maxWidth:150
        },
        {
            headerName: "Comment",
            field: "comment",
            editable: false
        }
    ];
};

export const editSKUVersionModalColDefs = (versions: Record<string, Record<string, any>>): (ColDef | ColGroupDef)[] => {
    return [
        {
            headerName: "Product Version",
            field: "product_version",
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            valueGetter: (params: ValueGetterParams) => {
                return versions[params.node?.data.version_id]?.description;
            }
        },
        {
            headerName: "SKU Type",
            field: "sku_type",
            editable: false
        },
        {
            headerName: "Country",
            field: "country",
            editable: false,
            maxWidth: 130
        },
        {
            headerName: "ROW",
            field: "row",
            editable: false,
            maxWidth: 130
        },
        {
            headerName: "Channel",
            field: "channel",
            editable: false,
            maxWidth: 200

        },
        {
            headerName: "Comment",
            field: "comment",
            editable: false
        }
    ];
};

export const addSKUModalColDefs = (versions: Record<string, Record<string, any>>): (ColDef | ColGroupDef)[] => {
    return [
        {
            headerName: "Product Version",
            field: "product_version",
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            valueGetter: (params: ValueGetterParams) => {
                return versions[params.node?.data.version_id]?.description;
            }
        },
        {
            headerName: "SKU Type",
            field: "sku_type",
            editable: false
        },
        {
            headerName: "Country",
            field: "country",
            editable: false,
            maxWidth: 130
        },
        {
            headerName: "ROW",
            field: "row",
            editable: false,
            maxWidth: 130
        },
        {
            headerName: "Channel",
            field: "channel",
            editable: false,
            maxWidth: 200

        },
        {
            headerName: "Comment",
            field: "comment",
            editable: false
        }
    ];
};

export const skuColDefs = (
    hasWriteAccess: boolean,
    hasDeactivateAccess: boolean,
    requestPNHandler: Function,
    addNotificationItem: Function,
    versions: Record<string, Record<string, any>>,
    toggleMPEditModal: Function,
    toggleSKUAssortmentsEditModal: Function,
    toggleSKUVersionEditModal: Function,
    deactivateSKUHandler: Function,
    reactivateSKUHandler: Function,
): (ColDef | ColGroupDef)[]  => {
    return [
        {
            headerName: "SKU id",
            field: "sku_id",
            editable: false,
            hide: true
        },
        {
            headerName: "Part Number",
            field: "pn",
            editable: false,
            cellRenderer: PartNumBtnRenderer,
            cellRendererParams: {
                requestPNHandler,
                addNotificationItem,
                hasWriteAccess
            }
        },
        {
            headerName: "ASIN",
            field: "asin",
            editable: false
        },
        {
            headerName: "Description",
            field: "description",
            editable: false,
            valueGetter: (params: ValueGetterParams) => {
                params.node!.data.description = versions[params.node?.data.version_id]?.description;
                params.node!.data.descriptionPN = versions[params.node?.data.version_id]?.descriptionPN;
                return params.node!.data.description;
            }
        },
        {
            headerName: "SKU Type",
            field: "sku_type",
            editable: false
        },
        {
            headerName: "Country",
            field: "country",
            editable: false
        },
        {
            headerName: "MarketPlace",
            field: "marketPlace",
            editable: false,
            valueGetter: (params: ValueGetterParams)=>{
                const data = params.node?.data;
                return concatWithoutDuplicate(data?.marketPlace, data?.additional_marketplaces).join(",");
            },
            cellRenderer: MarketPlaceRenderer,
            cellRendererParams:{
                toggleMPEditModal,
                hasWriteAccess
            }
        },
        {
            headerName: "Edit Assortments",
            editable: false,
            cellRenderer: EditSkuAssortmentsBtnRenderer,
            cellRendererParams: {
                toggleSKUAssortmentsEditModal,
                hasWriteAccess
            },
            maxWidth: 160
        },
        {
            headerName: "Edit Version",
            editable: false,
            cellRenderer: EditSkuVersionBtnRenderer,
            cellRendererParams: {
                toggleSKUVersionEditModal,
                hasWriteAccess,
                hasDeactivateAccess
            },
            maxWidth: 130
        },
        {
            headerName: "De/Reactivate",
            field: "is_deactivated",
            editable: false,
            valueGetter: (params: ValueGetterParams)=> params.node?.data.is_deactivated ? true : false,
            cellRenderer: DeactivateReactivateBtnRenderer,
            cellRendererParams: {
                deactivateSKUHandler,
                reactivateSKUHandler,
                addNotificationItem,
                hasWriteAccess,
                hasDeactivateAccess,
            },
            minWidth: 200
        }
    ];
};
