import { TAB_ID } from '../constants/constants';

export interface IAPIUserPolicyResp {
    "PK": string
    "SK": string
    "UserRole"?: string
}

export interface IUserPolicyMutationReq {
    "PK": string
    "SK": string
    "UserRole"?: string
    "Action": string
    "Module": string
}

export interface IUserPolicy {
    plAdmin: string[]
    inputProvider: string[]
}

export interface IIdName {
    "id": string,
    "name": string
}

export interface ILocationData {
    currentScreen: string
    productLine: IIdName
    program: IIdName
    fpn: IIdName
}

export interface ISelectOptions {
    "label": string
    "value": string
    "description"?: string
    "disabled"?: boolean
}

export interface IMutationParams {
    body: any
    apiName: string
}

export interface IAttributeListResponse {
    "attribute_type": number
    "id": number
    "attribute_value": string
}

export interface IAttributeTypeResponse {
    "attribute_type": string
    "is_std_attr": boolean
    "id": number
}

export interface IPLAttributeTypeResponse {
    "attribute_type": string
    "pl_attr_order": number
}

export interface IRPNAttributeTypeResponse {
    "attribute_type": string
    "rpn_attr_order": number
    "in_fpn": boolean
}

export interface IRPNAttributeListResponse {
    "attribute_type": string
    "attribute_value": string
}

export interface IProgramAttrListResponse {
    "attribute_type": string
    "attribute_value": string
    "asin_count": number
}

export interface IRpnFpnRuleMutation {
    "attribute_type": string
    "concat_order": number
    "verity_rpn_id": number
    "user_id": string
}

export interface IPlFPNRuleResponse {
    "attribute_type": string
    "concat_order": number
    "is_required": boolean
}

export interface IRPNAttrTypeMutation {
    "verity_rpn_id": number,
    "attribute_type": string,
    "rpn_attr_order":  number,
    "in_fpn": boolean,
    "user_id": string
}

export interface IAttrListMutation {
    "verity_rpn_id"?: number,
    "verity_fpn_id"?: number,
    "attribute_type": string,
    "add_attribute_value" : string[],
    "remove_attribute_value": string[]
    "user_id": string
}

export interface IFPNAttrListMutation {
    "verity_fpn_id": number,
    "attribute_type": string,
    "add_attribute_value" : string[],
    "remove_attribute_value": string[]
    "user_id": string
}

export interface IResponsePayload {
    "Action"?: string
    "PK"?: string
    "SK"?: string
    "Version"?: number
    "ItemValues"?: any
    "ItemValuesWIP"? : any
    "ItemValuesSubmitted"?: any
    "IsInheritedByFPN"?: boolean
    "IsPartOfFPN"?: boolean
    "SetAsTBD"?: boolean
    "Stage"?: string
    "UpdatedBy"?: any
    "Changes"?: string
}

export interface IApiFPNResponse {
    "verity_fpn_id": number
    "fpn": string
}

export interface IApiRPNResponse {
    "rpn": string
    "verity_rpn_id": number
    "is_on_roadmap": boolean
    "fpn": IApiFPNResponse[]
}

export interface IApiProductHierarchy {
    "verity_product_line_id": number
    "product_line": string
    "rpn": IApiRPNResponse[]
}

export interface IApiUserDetail {
    "business_title": string
    "employee_login": string
    "employee_name": string
    "department_name": string
}

export interface IApiUserResponse {
    "exact_match": IApiUserDetail[]
    "fuzzy_match": IApiUserDetail[]
}

export interface IFormInputProps {
    "name": string
    "label": string
    "readOnly"?: boolean,
    "toggleDisabled"?: boolean,
    "removeDisabled"?: boolean,
    "type"?: "select" | "multiselect",
    "values"?: ISelectOptions[] | ISelectOptions
    "all_options"?: ISelectOptions[]
    "IsInheritedByFPN"?: boolean
    "IsPartOfFPN"?: boolean
}

export interface IAttributesFormProps {
    "formInputs": IFormInputProps[]
    "action": (value: any, name: string) => void
    "reorder"?: boolean
    "reorderAction"?: (id: string) => void
    "fpnReadonly"?: boolean
    "isFPN"?: boolean
    "hideToggle"?: boolean
}

export enum PrfaqApprovalStatus {
    Yes= "Yes",
    No= "No",
    NA= "N/A"
}

export interface INewProgram {
    "rpn"?: string
    "is_name_approved"?: string
    "is_brd_approved"?: string
    "is_placeholder"?: string
    "is_root_program"?: boolean
    "product_line"?: any
    "verity_product_line_id"?: string | null
    "user_id"?: string
    "comment"?: string
    "prfaq_approval_status"?: PrfaqApprovalStatus | null
    "prfaq_link"?: string | null
}

export interface IUpdateProgram {
    "rpn"?: string
    "id"?: number
    "program_status"?: any,
    "is_name_approved"?: string
    "is_brd_approved"?: string
    "is_placeholder"?: string
    "is_on_roadmap"?: string
    "prfaq_approval_status"?: PrfaqApprovalStatus | null
    "prfaq_link"?: string | null
    "product_line"?: any
    "user_id"?: string
}

export interface IPostFPN {
    "add_fpn"?: string[]
    "remove_fpn"?: string[]
    "verity_RPN_id"?: number
    "user_id"?: string
}

export interface ISubmitTab {
    "currentTab": keyof typeof TAB_ID,
    "saveDisabled"?: boolean,
    "submitDisabled"?: boolean,
    "setIsLoading"?: any,
    "approveDisabled"?: boolean,
    "userRole": string,
    "section"?: string
}

export interface IVerityRPNResponse {
    "is_brd_approved": boolean
    "is_placeholder": boolean
    "is_name_approved": boolean
    "is_on_roadmap": boolean
    "prfaq_approval_status": PrfaqApprovalStatus | null
    "prfaq_link": string
    "rpn_alias": string[]
    "asin_count": number
    "ppls_id": string
    "product_code": string
    "rpn": string
    "program_status": string
    "created_ts": string
}

export interface IVerityFPNResponse {
    "asin_count": number
}

export interface IFPNsByRPNResponse {
    "fpn": string
    "verity_fpn_id": number;
    "is_current": boolean
    "asin_count": number
    "is_fpn_key_active": boolean
}

export interface ICountry {
    "country": string,
    "country_2_code": string
}

export interface IPLAttributeCreate {
    "attribute_type": string,
    "attribute_type_id": number,
    "product_line_id": number,
    "user_id": string,
    "pl_attr_order": number,
    "is_pl_attr": boolean,
    "is_inherited_by_fpn": boolean
}

export interface IAttributesOrdering {
    id: number,
    attribute: string,
    type: string,
    isRequired: boolean
}

export interface IPRMScenarios {
    is_automatic?: boolean,
    scenario_id: string,
    scenario_name: string
}

export interface IPRMFormResources {
    attribute_id: number,
    attribute_label: string,
    attribute_type: string,
    attribute_value: string
}

export interface IPRMGanttChartData {
    program_id: number,
    program_alias: string,
    start_date: string,
    end_date: string,
    attributes: IPRMFormResources[]
}
