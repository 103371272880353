import React, { useState, useEffect, useContext } from 'react';
import { Button, Container, Grid, Header, MultiselectProps, SpaceBetween, Spinner, Table, TableProps } from "@cloudscape-design/components";
import { TAB_ID_LABEL, ERROR_MESSAGE, USER_ASSIGNEES, MUTATION_ACTION, ALERT_MESSAGES, MUTATION_METHODS, STATUS_CODES, USER_ROLES, SEPERATOR, PERSON_TYPE, ALL_ASSIGNEES_KEY, USER_ACTION_ROLES } from '../../../constants/constants';
import { useGetProductLineDetailsQuery, useUpdateProductLineSetupMutation } from '../../../services/apis/productLineApi';
import { useUpdateUserAccessMutation } from '../../../services/apis/userPolicyApi';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import ScreenUtils from '../../../utils/screenUtils';
import { getUserOption } from '../../userSearch/userSearchMultiselect';
import { selectLocationData, selectUserDetailsMap, updateUserDetailsMap } from '../../appLayout/appLayoutSlice';
import { IResponsePayload } from 'src/components/RPT/interfaces/interfaces';
import { AssignUsersModal } from './assignUserModal';
import { RemoveAssignee } from './removeAssignee';
import AlertMessageContext from "../../../../common/Contexts/alertMessageContext";
import { IApiUpdateResponse } from 'src/components/Interfaces/interface';
import { selectRoleResourceMap } from '../userPolicySlice';
import UserPolicyHelper from '../userPolicyHelper';
import { IApiUserDetail } from 'src/components/RPT/interfaces/interfaces';
import { validateMultipleUserAliases, ValidateUserAliasResponse } from 'src/utils/papi-service';


type ISectionUser = {
    name: string,
    assignees: MultiselectProps.Option[]
}

const SECTIONS: string[] = [TAB_ID_LABEL.keyDates, TAB_ID_LABEL.financialInfo, TAB_ID_LABEL.manufacturingInfo, TAB_ID_LABEL.marketInfo, TAB_ID_LABEL.specs];

export const AssignUsers = () => {
    const dispatch = useAppDispatch();
    const locationData = useAppSelector(selectLocationData);
    const [userAliases, setUserAliases] = useState<string[]>([]);
    const { isLoading, isError, data: plData, isFetching } = useGetProductLineDetailsQuery(ScreenUtils.getPLPrimaryKey(locationData.productLine.id));
    const [updatePLDetails, { isLoading: mutationLoading, data: mutationData, isError: mutationError }] = useUpdateProductLineSetupMutation();
    const [updateUserAccess, { isLoading: accessMutationLoading, data: accessMutationData, isError: accessMutationError }] = useUpdateUserAccessMutation();
    const [tableItems, setTableItems] = useState<ISectionUser[]>([]);
    const userDetailsMap = useAppSelector(selectUserDetailsMap);
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const { getUserRole } = UserPolicyHelper();
    const [selectedItems, setSelectedItems] = useState<ISectionUser[]>([]);
    const [visible, setVisible] = useState(false);
    const [metadataPayload, setMetadataPayload] = useState('');
    const [serverDataMap, setServerDataMap] = useState<Record<string, IResponsePayload>>({});
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const { getNameFromOptLabel, getPLUserPolicyKey, getGraphQLPayload, generatePayload } = ScreenUtils;
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [usersLoading, setIsUsersLoading] = useState(false);

    const columnDefinitionsReadOnly: TableProps.ColumnDefinition<ISectionUser>[] = [
        {
            id: 'Section names',
            sortingField: 'Section names',
            header: 'Section names',
            cell: (item: ISectionUser) => item.name,
            minWidth: 180
        },
        {
            id: 'Assignees',
            sortingField: 'Assignees',
            header: 'Assignees',
            cell: (item: ISectionUser) => (
                <strong>{item.assignees?.map(opt => getNameFromOptLabel(opt?.label ?? '')).join(', ') ?? ''}</strong>
            ),
            minWidth: 140
        }
    ];

    const columnDefinitionsEdit: TableProps.ColumnDefinition<ISectionUser>[] = [
        ...columnDefinitionsReadOnly,
        {
            id: 'Action',
            sortingField: 'name',
            cell: (item: ISectionUser) => (
                hasWriteAccess && <RemoveAssignee
                    submit={submitRemove}
                    section={item.name}
                    assignees={item.assignees ?? []}
                />
            ),
            header: 'Action',
            minWidth: 100
        }
    ];
    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY]);
        USER_ACTION_ROLES.assignUser.includes(currRole) ? setHasWriteAccess(true) : setHasWriteAccess(false);
    }, [roleResMap, locationData]);

    useEffect(() => {
        plData?.[USER_ASSIGNEES]?.[USER_ROLES.inputProvider] ? setServerDataMap(ScreenUtils.formatData(plData[USER_ASSIGNEES][USER_ROLES.inputProvider])) : setServerDataMap({});
    }, [plData]);

    useEffect(() => {
        const aliases: string[] = [];
        Object.values(serverDataMap).forEach((obj: IResponsePayload) => {
            obj.ItemValues?.forEach((alias: string) => {
                if (!userDetailsMap[alias] && !aliases.includes(alias)) aliases.push(alias);
            });
        });
        setUserAliases(aliases);
    }, [serverDataMap]);

    useEffect(() => {
        const aliases: string[] = [];
        // Collect unique user aliases that aren't in the userDetailsMap
        tableItems?.forEach((section: ISectionUser) => {
            if (section?.assignees) {
                section.assignees.forEach((assignee) => {
                    if (assignee?.value && !userDetailsMap[assignee.value]) {
                        aliases.push(assignee.value);
                    }
                });
            }
        });
        setUserAliases(aliases);
    }, [tableItems, userDetailsMap]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (userAliases.length > 0) {
                setIsUsersLoading(true);
                try {
                    const response = await validateMultipleUserAliases(userAliases);
                    const userMap: Record<string, MultiselectProps.Option> = {};
                    response.forEach((userResponse: ValidateUserAliasResponse) => {
                        if (userResponse.isValid && userResponse.userInfo) {
                            const { login, firstName, lastName, costCenterName, businessTitle } = userResponse.userInfo;
                            const apiUserDetail: IApiUserDetail = {
                                employee_login: login,
                                employee_name: `${firstName} ${lastName}`,
                                department_name: costCenterName,
                                business_title: businessTitle
                            };
                            userMap[login] = getUserOption(apiUserDetail);
                        }
                    });
                    dispatch(updateUserDetailsMap(userMap));
                } catch (error) {
                    console.error('Error fetching user details:', error);
                } finally {
                    setIsUsersLoading(false);
                }
            }
        };
        fetchUserDetails();
    }, [userAliases]);

    useEffect(() => {
        const items: ISectionUser[] = [];
        const sectionAssigneeNameMap: Record<string, MultiselectProps.Option[]> = {};
        Object.entries(serverDataMap).forEach(([section, obj]) => {
            sectionAssigneeNameMap[section] = obj.ItemValues.filter((alias: string) => userDetailsMap[alias]).map((alias: string) => userDetailsMap[alias]);
        });
        SECTIONS.forEach((section: string) => {
            items.push({
                name: section,
                assignees: sectionAssigneeNameMap[section]
            });
        });
        setTableItems(items);
    }, [userDetailsMap, serverDataMap]);

    const submitAssign = (newAssignees: MultiselectProps.Option[]) => {
        setVisible(false);
        const sections = selectedItems.map(item => item.name);
        const metadataRequest: IResponsePayload[] = [];
        const userPolicyResources: any[] = [];
        const aliases = newAssignees?.map(opt => opt.value ?? '') ?? [];
        sections.forEach(section => {
            const serverData = serverDataMap[section];
            metadataRequest.push(serverData ? {
                ...serverData,
                ItemValues: [...serverData.ItemValues, ...aliases],
                Action: MUTATION_ACTION.update,
                Changes: serverData.ItemValues.toString()
            } : {
                PK: ScreenUtils.getPLPrimaryKey(locationData.productLine.id),
                SK: USER_ASSIGNEES + SEPERATOR + USER_ROLES.inputProvider + SEPERATOR + section,
                ItemValues: aliases,
                Action: MUTATION_ACTION.put,
                Version: 0
            });
            userPolicyResources.push({
                name: getPLUserPolicyKey(locationData.productLine.id),
                resources: { name: section }
            });
        });
        const users = aliases.map(alias => {
            return { type: PERSON_TYPE, value: alias };
        });
        const userPolicyPayload = {
            action: MUTATION_ACTION.addPolicy,
            item: {
                users: users,
                roles: [{ name: USER_ROLES.inputProvider }],
                resources: userPolicyResources
            }
        };
        if (metadataRequest.length) setMetadataPayload(generatePayload(metadataRequest) ?? '');
        userPolicyUpdate(getGraphQLPayload(userPolicyPayload));
        setSelectedItems([]);
    };

    const submitRemove = (section: string, allAssignees: MultiselectProps.Option[], updatedAssignees: MultiselectProps.Options) => {
        const serverData = serverDataMap[section];
        const updatedAliases = updatedAssignees.map((opt: MultiselectProps.Option) => opt.value);
        const removedAliases = allAssignees.map(opt => opt.value).filter(alias => !updatedAliases.includes(alias));
        const metadataRequest = [{
            ...serverData,
            ItemValues: updatedAliases,
            Action: MUTATION_ACTION.update,
            Changes: serverData.ItemValues.toString()
        }];
        const users = removedAliases.map(alias => {
            return { type: PERSON_TYPE, value: alias };
        });
        const userPolicyPayload = {
            action: MUTATION_ACTION.deletePolicy,
            item: {
                users: users,
                roles: [{ name: USER_ROLES.inputProvider }],
                resources: {
                    name: getPLUserPolicyKey(locationData.productLine.id),
                    resources: { name: section }
                }
            }
        };
        if (metadataRequest.length) setMetadataPayload(generatePayload(metadataRequest) ?? '');
        userPolicyUpdate(getGraphQLPayload(userPolicyPayload));
        setSelectedItems([]);
    };

    const metadataUpdate = async (mutationPayload: string) => {
        console.log(mutationPayload);// console added for beta testing
        await updatePLDetails(mutationPayload).unwrap();
    };

    const userPolicyUpdate = async (mutationPayload: string) => {
        console.log(mutationPayload);// console added for beta testing
        await updateUserAccess(mutationPayload).unwrap();
    };

    useEffect(() => {
        if (mutationData?.errors || mutationError) setError?.(ALERT_MESSAGES.updateFailure);
        if (mutationData?.data?.[MUTATION_METHODS.updateProgramSetup]) {
            const { statusCode }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.updateProgramSetup];
            statusCode === STATUS_CODES.success ? setSuccess?.(ALERT_MESSAGES.updateSuccess) : setError?.(ALERT_MESSAGES.updateFailure);
        }
    }, [mutationData, mutationError]);

    useEffect(() => {
        if (accessMutationData?.errors || accessMutationError) setError?.(ALERT_MESSAGES.updateFailure);
        if (accessMutationData?.data?.[MUTATION_METHODS.updateUserAccess]) {
            const { statusCode }: IApiUpdateResponse = accessMutationData.data[MUTATION_METHODS.updateUserAccess];
            statusCode === STATUS_CODES.success ? metadataUpdate(metadataPayload) : setError?.(ALERT_MESSAGES.updateFailure);
        }
    }, [accessMutationData, accessMutationError]);

    if (isError) return (
        <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
            <Container>
                {ERROR_MESSAGE}
            </Container>
        </Grid>
    );
    const loadTable = () => {
        if (isLoading || isFetching || usersLoading || mutationLoading || accessMutationLoading) return <div className='loadspinner mg-top-md'><Spinner size="large" /></div>;
        if (tableItems?.length) {
            return (
                <>
                    {hasWriteAccess ? <Table
                        selectionType="multi"
                        columnDefinitions={columnDefinitionsEdit}
                        items={tableItems}
                        selectedItems={selectedItems}
                        sortingDisabled
                        variant="embedded"
                        wrapLines
                        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                    /> :
                        <Table
                            columnDefinitions={columnDefinitionsReadOnly}
                            items={tableItems}
                            selectedItems={selectedItems}
                            sortingDisabled
                            variant="embedded"
                            wrapLines
                            onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                        />}
                </>
            );
        } else {
            return ERROR_MESSAGE;
        }
    };

    return (
        <>
            <Container
                header={
                    <Header
                        actions={
                            hasWriteAccess && <SpaceBetween direction="horizontal" size="xs">
                                <Button className='bg-primary' disabled={!selectedItems.length} onClick={() => setVisible(true)} variant="primary">
                                    Assign user
                                </Button>
                            </SpaceBetween>
                        }
                        variant="h2">
                        Assign users
                    </Header>
                }
            >
                {loadTable()}
                {visible &&
                    <AssignUsersModal
                        setVisible={setVisible}
                        visible={visible}
                        submit={submitAssign}
                        selectedSections={selectedItems.map(item => item.name).join(', ')}
                    />}
            </Container>
        </>


    );
};

