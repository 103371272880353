import Queries from '../queries/queries';
import { API_METHODS, MUTATION_METHODS } from '../../constants/constants';
import { verityApi } from '../../../redux/api';

const { getVerions, getAttrOrder, getUpdateQuery, getAssortments, removeVersions, removeAssortments, updateAssortments, getSku, addSku, updateSkuAssortments, updateSkuVersionType, requestPN, deactivateSKU, reactivateSKU, getMarketPlaces, updateMarketPlace } = Queries;

export const agmApis = verityApi.injectEndpoints({
    endpoints: (build) => ({
        getVersions: build.query({
            query: ({ verity_rpn_id }) => ({
                schemaName: 'get_agm_prog_version',
                method: API_METHODS.post, data: {
                    "query": getVerions(),
                    "variables": { "verity_rpn_id": verity_rpn_id }
                }
            }),
            providesTags: () => [{ type: 'VersionsAGM' }],
            transformResponse: (response: any) => response.data.data?.get_agm_prog_version?.body ?? null
        }),
        getProgAttrOrder: build.query({
            query: ({ verity_rpn_id }) => ({
                schemaName: 'get_agm_prog_att_sort',
                method: API_METHODS.post, data: {
                    "query": getAttrOrder(),
                    "variables": { "verity_rpn_id": verity_rpn_id }
                }
            }),
            providesTags: () => [{ type: 'AGMAttrOrder' }],
            transformResponse: (response: any) => response.data.data?.get_agm_prog_att_sort?.body ?? null
        }),

        getAssortments: build.query({
            query: ({ verity_rpn_id }) => ({
                schemaName: 'get_agm_assort',
                method: API_METHODS.post, data: {
                    "query": getAssortments(),
                    "variables": { "verity_rpn_id": verity_rpn_id }
                }
            }),
            providesTags: () => [{ type: 'AGMAssortments' }],
            transformResponse: (response: any) => {
                const error = response.data.errors || response.data.data?.get_agm_assort.error;
                if(error){
                    throw new Error(error);
                }
                return response.data.data?.get_agm_assort?.body ?? null;
            }
        }),

        updateVersions: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: getUpdateQuery(data, MUTATION_METHODS.updateAgmVersions) }),
            invalidatesTags: () => [{ type: 'VersionsAGM' }],
            transformResponse: (response: any) => response.data
        }),

        removeVersion: build.mutation({
            query:(payload) => ({
                schemaName: 'delete_agm_version',
                method: API_METHODS.post,
                data: {
                    "query": removeVersions(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'VersionsAGM' }, { type: 'AGMAssortments' }, { type: 'AGMSku' }],
            transformResponse: (response: any) => response.data.data.delete_agm_version
        }),

        updateAttrOrder: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: getUpdateQuery(data, MUTATION_METHODS.updateAttrOrder) }),
            invalidatesTags: () => [{ type: 'AGMAttrOrder' }],
            transformResponse: (response: any) => response.data
        }),

        updateAssortment: build.mutation({
            query:(payload) => ({
                schemaName: 'update_agm_assort',
                method: API_METHODS.post,
                data: {
                    "query": updateAssortments(), "variables": {
                        ...payload
                    }
                }
            }),
            transformResponse: (response: any) => response.data.data.update_agm_assort
        }),

        addAssortment: build.mutation({
            query:(payload) => ({
                schemaName: 'update_agm_assort',
                method: API_METHODS.post,
                data: {
                    "query": updateAssortments(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'AGMAssortments' }],
            transformResponse: (response: any) => response.data.data.update_agm_assort
        }),

        removeAssortment: build.mutation({
            query:(payload) => ({
                schemaName: 'remove_agm_assort',
                method: API_METHODS.post,
                data: {
                    "query": removeAssortments(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'AGMAssortments' }, { type: 'AGMSku' }],
            transformResponse: (response: any) => response.data.data.remove_agm_assort
        }),

        getSku: build.query({
            query: ({ verity_rpn_id }) => ({
                schemaName: 'get_agm_sku',
                method: API_METHODS.post, data: {
                    "query": getSku(),
                    "variables": { "verity_rpn_id": verity_rpn_id }
                }
            }),
            providesTags: () => [{ type: 'AGMSku' }],
            transformResponse: (response: any) => {
                const error = response.data.errors || response.data.data?.get_agm_sku.error;
                if(error){
                    throw new Error(error);
                }
                return response.data.data?.get_agm_sku?.body ?? null;
            }
        }),

        addSku: build.mutation({
            query:(payload) => ({
                schemaName: 'add_agm_new_sku',
                method: API_METHODS.post,
                data: {
                    "query": addSku(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'AGMSku' }],
            transformResponse: (response: any) => response.data.data.add_agm_new_sku
        }),

        updateSkuAssortments: build.mutation({
            query:(payload) => ({
                schemaName: 'update_agm_sku_assortments',
                method: API_METHODS.post,
                data: {
                    "query": updateSkuAssortments(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'AGMSku' }],
            transformResponse: (response: any) => response.data.data.update_agm_sku_assortments
        }),

        updateSkuVersionType: build.mutation({
            query:(payload) => ({
                schemaName: 'update_agm_sku_version_and_type',
                method: API_METHODS.post,
                data: {
                    "query": updateSkuVersionType(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'AGMSku' }],
            transformResponse: (response: any) => response.data.data.update_agm_sku_version_and_type
        }),

        deactivateSKU: build.mutation({
            query:(payload) => ({
                schemaName: 'deactivate_agm_sku',
                method: API_METHODS.post,
                data: {
                    "query": deactivateSKU(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'AGMSku' }],
            transformResponse: (response: any) => response.data.data.deactivate_agm_sku
        }),
        
        reactivateSKU: build.mutation({
            query:(payload) => ({
                schemaName: 'reactivate_agm_sku',
                method: API_METHODS.post,
                data: {
                    "query": reactivateSKU(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'AGMSku' }],
            transformResponse: (response: any) => response.data.data.reactivate_agm_sku
        }),

        requestPN: build.mutation({
            query:(payload) => ({
                schemaName: 'request_pn',
                method: API_METHODS.post,
                data: {
                    "query": requestPN(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'AGMSku' }],
            transformResponse: (response: any) => response.data.data.request_pn
        }),

        getMarketPlaces: build.query({
            query: () => ({
                schemaName: 'get_marketplace',
                method: API_METHODS.post, data: {
                    "query": getMarketPlaces()
                }
            }),
            transformResponse: (response: any) => {
                const error = response.data.data?.get_marketplace.error;
                if(error){
                    throw new Error(error);
                }
                return response.data.data?.get_marketplace?.body ?? null;
            }
        }),

        updateMarketPlace: build.mutation({
            query:(payload) => ({
                schemaName: 'update_agm_sku_mp',
                method: API_METHODS.post,
                data: {
                    "query": updateMarketPlace(), "variables": {
                        ...payload
                    }
                }
            }),
            invalidatesTags: () => [{ type: 'AGMSku' }],
            transformResponse: (response: any) => response.data.data.update_agm_sku_mp
        })
    })
});

export const {
    useGetVersionsQuery,
    useGetProgAttrOrderQuery,
    useGetAssortmentsQuery,
    useLazyGetAssortmentsQuery,
    useUpdateVersionsMutation,
    useUpdateAttrOrderMutation,
    useUpdateAssortmentMutation,
    useAddAssortmentMutation,
    useRemoveVersionMutation,
    useRemoveAssortmentMutation,
    useGetSkuQuery,
    useLazyGetSkuQuery,
    useAddSkuMutation,
    useUpdateSkuAssortmentsMutation,
    useUpdateSkuVersionTypeMutation,
    useDeactivateSKUMutation,
    useReactivateSKUMutation,
    useRequestPNMutation,
    useGetMarketPlacesQuery,
    useUpdateMarketPlaceMutation
} = agmApis;