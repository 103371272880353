import { Button } from '@amzn/awsui-components-react';
import React from 'react';


export function EditSkuAssortmentsBtnRenderer(props: any) {

    const onClick = async () => {
        props.toggleSKUAssortmentsEditModal(true , props.node.data);
    };


    return(
        <>
            <div className='sku_edit_btn_renderer'>
                <div className='btn'><Button  variant="icon" iconName='edit' onClick={onClick} disabled={!props.hasWriteAccess}/></div>
            </div>
        </>
    );
};

export function EditSkuVersionBtnRenderer(props: any) {

    const onClick = async () => {
        props.toggleSKUVersionEditModal(true , props.node.data);
    };


    return(
        <>
            <div className='sku_edit_btn_renderer'>
                <div className='btn'><Button  variant="icon" iconName='edit' onClick={onClick} disabled={!props.hasDeactivateAccess}/></div>
            </div>
        </>
    );
}
