import React, { useEffect, useState } from 'react';
import { Box, MultiselectProps, Spinner } from "@cloudscape-design/components";
import { selectProgAssignees, selectPLAssignees } from '../userPolicySlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { USER_ROLES } from '../../../constants/constants';
import { selectUserDetailsMap, updateUserDetailsMap } from '../../appLayout/appLayoutSlice';
import { IApiUserDetail } from 'src/components/RPT/interfaces/interfaces';
import { getUserOption } from '../../userSearch/userSearchMultiselect';
import ScreenUtils from '../../../utils/screenUtils';
import UserPolicyHelper from '../userPolicyHelper';
import { validateMultipleUserAliases, ValidateUserAliasResponse } from 'src/utils/papi-service';

export interface IAssignUserModal {
    tab: string,
    section: string
}

export const ProgramAssignees = ({ tab, section }: IAssignUserModal) => {
    const dispatch = useAppDispatch();
    const allProgAssignees = useAppSelector(selectProgAssignees);
    const allPLAssignees = useAppSelector(selectPLAssignees);
    const { getNameFromOptLabel } = ScreenUtils;
    const { getProgAssignees, getPLAssignees } = UserPolicyHelper();
    const progAssignees = getProgAssignees(allProgAssignees, USER_ROLES.inputProvider, tab, section);
    const plAssignees = getPLAssignees(allPLAssignees, USER_ROLES.inputProvider, tab, '');
    const userDetailsMap = useAppSelector(selectUserDetailsMap);
    const [userAliases, setUserAliases] = useState<string[]>([]);
    const [displayUsers, setDisplayUsers] = useState('');
    const [usersLoading, setIsUsersLoading] = useState(false);
    const [userData, setUserData] = useState<ValidateUserAliasResponse[]>([]);

    const fetchUserDetails = async (aliases: string[]) => {
        if (aliases.length === 0) return;

        setIsUsersLoading(true);
        try {
            const response = await validateMultipleUserAliases(aliases);
            if (response) {
                setUserData(response);
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        } finally {
            setIsUsersLoading(false);
        }
    };

    useEffect(() => {
        fetchUserDetails(userAliases);
    }, [userAliases]);

    useEffect(() => {
        setDisplayUsers(Array.from(new Set([...(progAssignees?.length ? progAssignees : []), ...(plAssignees?.length ? plAssignees : [])])).map(alias => getNameFromOptLabel(userDetailsMap?.[alias]?.label ?? '')).join(', '));
    }, [userDetailsMap, progAssignees, plAssignees]);

    useEffect(() => {
        const aliases: string[] = [];
        Array.from(new Set([...(progAssignees?.length ? progAssignees : []), ...(plAssignees?.length ? plAssignees : [])]))?.forEach((alias: string) => {
            if (!userDetailsMap[alias]) aliases.push(alias);
        });
        if (aliases.length) setUserAliases(aliases);
    }, [plAssignees, progAssignees]);

    useEffect(() => {
        if (userData) {
            const userMap: Record<string, MultiselectProps.Option> = {};
            userData.forEach((response: ValidateUserAliasResponse) => {
                if (response.isValid && response.userInfo) {
                    const { login, firstName, lastName, costCenterName, businessTitle } = response.userInfo;
                    const apiUserDetail: IApiUserDetail = {
                        employee_login: login,
                        employee_name: `${firstName} ${lastName}`,
                        department_name: costCenterName,
                        business_title: businessTitle
                    };
                    userMap[login] = getUserOption(apiUserDetail);
                }
            });
            dispatch(updateUserDetailsMap(userMap));
        }
    }, [userData]);

    return (
        <Box padding={{ left: "l" }} color="text-body-secondary" variant="span">Assignees<Box variant="span" className='user-list'>{usersLoading ? <Spinner /> : displayUsers}</Box></Box>
    );
};
