import React, { useContext, useEffect, useState } from "react";
import { Button, SpaceBetween } from "@cloudscape-design/components";
import { useAppSelector } from "../../../redux/hooks";
import { useUpdateAndApproveProgramMutation } from '../../services/api';
import { ALERT_MESSAGES, ALL_ASSIGNEES_KEY, MUTATION_METHODS, STATUS_CODES, TAB_ID_LABEL, USER_ACTION_ROLES } from '../../constants/constants';
import { IApiUpdateResponse } from "../../../Interfaces/interface";
import AlertMessageContext from "../../../common/Contexts/alertMessageContext";
import ScreenUtils from "../../utils/screenUtils";
import { selectClientData, selectContactInfo, selectOverview, selectOverviewModified, selectVerityData } from "./programOverviewSlice";
import { selectLocationData } from "../appLayout/appLayoutSlice";
import { selectRoleResourceMap } from "../userPolicy/userPolicySlice";
import UserPolicyHelper from '../userPolicy/userPolicyHelper';

export const SubmitOverview = ({ setIsUpdateLoading }: any) => {

    const clientData = useAppSelector(selectClientData);
    const isModified = useAppSelector(selectOverviewModified);
    const locationData = useAppSelector(selectLocationData);
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const [updateProgramSetup, { isLoading: mutationLoading, data: mutationData, isError: mutationError }] = useUpdateAndApproveProgramMutation();
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const { getUserRole } = UserPolicyHelper();
    const programData = useAppSelector(selectVerityData);
    const programOverview = useAppSelector(selectOverview);
    const contactInfo = useAppSelector(selectContactInfo);

    const requiredOverviewAttributesInRDSData = ScreenUtils.getCurrentTabRequiredFields("overviewRDSData");
    const requiredOverviewAttributesInDynamo = ScreenUtils.getCurrentTabRequiredFields("overviewDynamoData");
    const requiredOverviewAttributesInContactInfo = ScreenUtils.getCurrentTabRequiredFields("overviewContactInfoData");
    const { hasNullRequiredFields: hasNullRequiredFieldsProgramData, nullRequiredFields: nullRequiredFieldsProgramData } = 
    ScreenUtils.checkRequiredFieldsNullOrUndefined(programData, requiredOverviewAttributesInRDSData);

    const { hasNullRequiredFields: hasNullRequiredFieldsProgramOverview, nullRequiredFields: nullRequiredFieldsProgramOverview } = 
        ScreenUtils.checkRequiredFieldsNullOrUndefined(programOverview, requiredOverviewAttributesInDynamo);

    const { hasNullRequiredFields: hasNullRequiredFieldsContactInfo, nullRequiredFields: nullRequiredFieldsContactInfo } = 
        ScreenUtils.checkRequiredFieldsNullOrUndefined(contactInfo, requiredOverviewAttributesInContactInfo);

    const hasNullRequiredFields = hasNullRequiredFieldsProgramData || hasNullRequiredFieldsProgramOverview || hasNullRequiredFieldsContactInfo;

    const nullRequiredFields = [
        ...nullRequiredFieldsProgramData,
        ...nullRequiredFieldsProgramOverview,
        ...nullRequiredFieldsContactInfo
    ];

    const saveDisabled = !isModified || !hasWriteAccess || hasNullRequiredFields;

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY]);
        if (USER_ACTION_ROLES.approve.includes(currRole)) setHasWriteAccess(true);
    }, [roleResMap, locationData]);

    useEffect(() => {
        setIsUpdateLoading(mutationLoading);
    }, [mutationLoading]);

    useEffect(() => {
        if (mutationData) {
            if (mutationData.errors || mutationError) setError?.(ALERT_MESSAGES.updateFailure);
            if (mutationData.data?.[MUTATION_METHODS.saveApproveProgram]) {
                const { statusCode }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.saveApproveProgram];
                statusCode === STATUS_CODES.success ? setSuccess?.(ALERT_MESSAGES.updateSuccess) : setError?.(ALERT_MESSAGES.updateFailure);
            }
        }
    }, [mutationData, mutationError]);

    const submit = () => {
        setIsUpdateLoading(true);
        const allValues = [...Object.values(clientData.overview), ...Object.values(clientData.contactInfo), ...Object.values(clientData.documentLinks)]
            .filter(obj => {
                if (obj.Action) return obj;
            });
        if (allValues.length) {
            const payload = ScreenUtils.generatePayload(allValues);
            if (payload) handleUpdate(payload);
        }
    };

    const handleUpdate = async (mutationPayload: string) => {
        console.log(mutationPayload);// console added for beta testing
        await updateProgramSetup(mutationPayload).unwrap();
    };

    return (
        <>
            <SpaceBetween direction="horizontal" size="s">
                {ScreenUtils.renderMissingFieldsWarningMessage({
                    hasNullFields: hasNullRequiredFields,
                    nullFields: nullRequiredFields
                })}
                <div style={{ flexShrink: 0 }}>
                    <Button 
                        className="bg-primary" 
                        disabled={saveDisabled} 
                        onClick={submit} 
                        variant="primary"
                    >
                        Save {TAB_ID_LABEL.overview}
                    </Button>
                </div>
            </SpaceBetween>
        </>
    );

};
