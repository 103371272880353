import AWS from 'aws-sdk'
import { Auth } from "aws-amplify"
import { getAuthConfig } from '../Auth/AuthConfiguration';

export interface WebSocketConfig {
	development: string,
	test: string,
	prod: string
}

const WEBSOCKET_CONFIGS: WebSocketConfig = {
	development: 'wss://vwq1q573pb.execute-api.us-east-1.amazonaws.com/alpha',
	test: 'wss://aguih70pfj.execute-api.us-east-1.amazonaws.com/beta',
	prod: 'wss://0ychxob77c.execute-api.us-east-1.amazonaws.com/prod'
}

// Stage value comes crom website CDK package. Make sure same values are used in each website CDK package
export function getEnvFromUrl () {
    const stage = "prod";
    if (stage.match('beta') != null)
      return 'test';
    if (stage.match('prod') != null)
      return 'prod';
    return 'development';
}

export function getWebSocketUrl (token: String) {
    return WEBSOCKET_CONFIGS[getEnvFromUrl()] + `?token=${token}`
}

export const loadAccessInfo = async () => {
    await Auth.currentAuthenticatedUser()
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const authConfig = getAuthConfig()
    const userPool = `cognito-idp.${authConfig.region}.amazonaws.com/${authConfig.userPoolId}`
    AWS.config.region = authConfig.region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: authConfig.identityPoolId,
        Logins: {
            [userPool]: token
        }
    });
    return new Promise((resolve) => {
        (AWS.config.credentials as AWS.CognitoIdentityCredentials).get(function () {
            const accessInfo = {
                access_key: (AWS.config.credentials as AWS.CognitoIdentityCredentials).accessKeyId,
                secret_key: (AWS.config.credentials as AWS.CognitoIdentityCredentials).secretAccessKey,
                session_token: (AWS.config.credentials as AWS.CognitoIdentityCredentials).sessionToken
            }
            resolve([accessInfo, token])
        })
    })
}