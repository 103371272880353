import React, { useState, useEffect } from 'react';
import { ColumnLayout, Container, Header, MultiselectProps, Spinner } from "@cloudscape-design/components";
import { SK_MAPPING, TAB_ID_LABEL, SEPERATOR, API_RESPONSE_FIELDS, USER_ACTION_ROLES } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { CONTACT_INFO, IFieldMetaData } from '../../constants/fieldData';
import { updateContactInfo, selectContactInfo, selectUserRole } from '../programOverview/programOverviewSlice';
import ScreenUtils from '../../utils/screenUtils';
import { UserSearchMultiselect, IUserMultiselectProps, getUserOption } from '../userSearch/userSearchMultiselect';
import { selectLocationData, selectUserDetailsMap, updateUserDetailsMap } from '../appLayout/appLayoutSlice';
import { IResponsePayload } from 'src/components/RPT/interfaces/interfaces';
import { IApiUserDetail } from 'src/components/RPT/interfaces/interfaces';
import { validateMultipleUserAliases, ValidateUserAliasResponse } from 'src/utils/papi-service';

export const ContactInformation = () => {
    const dispatch = useAppDispatch();
    const contactInfo = useAppSelector(selectContactInfo);
    const locationData = useAppSelector(selectLocationData);
    const userDetailsMap = useAppSelector(selectUserDetailsMap);
    const [formInput, setFormInput] = useState<IUserMultiselectProps[]>();
    const [usersLoading, setIsUsersLoading] = useState(false);
    const hasWriteAccess = USER_ACTION_ROLES.approve.includes(useAppSelector(selectUserRole));

    const updateUserValues = (fieldValue: MultiselectProps.Option[], fieldName: string) => {
        const userMap: Record<string, MultiselectProps.Option> = {};
        const values: string[] = [];
        const PK = ScreenUtils.getPrimaryKey(locationData, 'Sandbox');
        const SK = TAB_ID_LABEL.overview + SEPERATOR + SK_MAPPING.contactInfo + SEPERATOR + fieldName;
        fieldValue.forEach((obj: MultiselectProps.Option) => {
            if (obj.value) {
                if (!userDetailsMap[obj.value]) userMap[obj.value] = obj;
                values.push(obj.value);
            }
        });
        dispatch(updateUserDetailsMap(userMap));
        dispatch(updateContactInfo(ScreenUtils.updateClientState(values, fieldName, API_RESPONSE_FIELDS.itemValues, contactInfo, PK, SK)));
    };

    const fetchUserDetails = async (aliases: string[]) => {
        if (aliases.length === 0) return;

        setIsUsersLoading(true);
        try {
            const response = await validateMultipleUserAliases(aliases);
            if (response) {
                const userMap: Record<string, MultiselectProps.Option> = {};
                response.forEach((userResponse: ValidateUserAliasResponse) => {
                    if (userResponse.isValid && userResponse.userInfo) {
                        const { login, firstName, lastName, costCenterName, businessTitle } = userResponse.userInfo;
                        const apiUserDetail: IApiUserDetail = {
                            employee_login: login,
                            employee_name: `${firstName} ${lastName}`,
                            department_name: costCenterName,
                            business_title: businessTitle
                        };
                        userMap[login] = getUserOption(apiUserDetail);
                    }
                });
                dispatch(updateUserDetailsMap(userMap));
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        } finally {
            setIsUsersLoading(false);
        }
    };


    // Effect to fetch user details when contactInfo changes
    useEffect(() => {
        const aliases: string[] = [];
        Object.values(contactInfo).forEach((obj: IResponsePayload) => {
            obj.ItemValues.forEach((alias: string) => {
                if (!userDetailsMap[alias]) aliases.push(alias);
            });
        });
        if (aliases.length > 0) {
            fetchUserDetails(aliases);
        }
    }, [contactInfo]);

    useEffect(() => {
        const fMetaData: Record<string, IFieldMetaData> = { ...CONTACT_INFO };
        const props: IUserMultiselectProps[] = [];
        Object.entries(fMetaData).forEach(([field]) => {
            const values: MultiselectProps.Option[] = [];
            if (contactInfo[field] && contactInfo[field].ItemValues) {
                contactInfo[field].ItemValues.forEach((alias: string) => {
                    if (userDetailsMap[alias]) values.push(userDetailsMap[alias]);
                });
            }
            props.push({
                name: field,
                selectedValues: values,
                label: fMetaData[field].label,
                readOnly: locationData.fpn.id ? true : !hasWriteAccess,
                action: updateUserValues
            });
        });
        setFormInput(props);
    }, [contactInfo, userDetailsMap, hasWriteAccess, locationData.fpn.id]);

    const loadContactEdit = () => {
        if (usersLoading) return <div className='loadspinner mg-top-md'><Spinner size="large" /></div>;
        if (formInput) {
            return (
                <>
                    <ColumnLayout columns={2}>
                        {formInput.map((field: IUserMultiselectProps) => {
                            return (
                                <div key={field.name}>
                                    <UserSearchMultiselect
                                        name={field.name}
                                        selectedValues={field.selectedValues}
                                        label={field.label}
                                        readOnly={field.readOnly ?? false}
                                        action={field.action}
                                    />
                                </div>
                            );
                        })}
                    </ColumnLayout>
                </>
            );
        } else {
            return "No data to display.";
        }
    };

    return (
        <>
            <Container
                header={
                    <Header variant="h2">
                        Contact Information
                    </Header>
                }
            >
                {loadContactEdit()}
            </Container>
        </>

    );
};
