import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { GridTable } from 'src/components/common/GridTable/GridTable';
import { useAppDispatch, useAppSelector } from 'src/components/redux/hooks';
import { getAssortments } from '../assortment/assortmentSlice';
import { selectVersions } from '../versions/versionSlice';
import { Box, Button, FlashbarProps, Modal, SpaceBetween, Toggle, ToggleProps } from '@amzn/awsui-components-react';
import { useUpdateSkuAssortmentsMutation } from '../../services/apis/api';
import { getAssignedAssortments, getSelectedAssortments, updateSelectedAssortments } from './skuSlice';
import { assortment } from '../../interfaces/assortmentInterface';
import { AgGridApi, skuPayloadItem } from '../../interfaces/skuInterface';
import { compareArrays } from './skuHelpers';
import { editSKUAssortmentsModalColDefs } from './columnDefs';

export default function EditSkuAssortmentsModal({ visible, setEditSKUAssortmentsModalVisible, addNotificationItem, editingSKU }: {
    visible: boolean,
    setEditSKUAssortmentsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    addNotificationItem: (notificationItem: FlashbarProps.MessageDefinition) => void,
    editingSKU: any
}) {

    //hooks
    const dispatch = useAppDispatch();
    const [ updateSkuAssortments ] = useUpdateSkuAssortmentsMutation();


    //from store
    const assignedAssortments = useAppSelector(getAssignedAssortments);
    const { assortments } = useAppSelector(getAssortments);
    const versions = useAppSelector(selectVersions);
    const selectedAssortments = useAppSelector(getSelectedAssortments);

    //state
    const [savingSku, setSavingSku] = useState(false);
    const [hideAssigned, setHideAssigned] = useState(true);

    //Refs
    const apiRef = useRef<AgGridApi>({
        grid: undefined,
        column: undefined
    });

    //locals
    const getRowId = (params: any) => {
        return params.assortment_id;
    };

    const onGridReady = (event: any): void => {
        apiRef.current.grid = event.api;
        apiRef.current.column = event.columnApi;
    };


    const onModelUpdated = (params: any)=>{
        Object.values(assortments ?? {}).forEach((assortment)=>{
            if(editingSKU.groupedAssortmentIDs.includes(assortment.assortment_id)){
                params.api.getRowNode(`${assortment.assortment_id}`).setSelected(true);
            }
        });
    };

    const onRowSelection = (selectedRows: assortment[]) => {
        const selectedItems = selectedRows.reduce((map, item) => {
            const key = `${item.version_id}_${item.sku_type}`;
            if (map[key]) {
                map[key].assortment_ids = [...map[key].assortment_ids, item.assortment_id];
            } else {
                map = {
                    ...map,
                    [key]: {
                        version_id: item.version_id,
                        sku_type: item.sku_type,
                        assortment_ids: [item.assortment_id]
                    }
                };
            }
            return map;
        }, {} as Record<string, skuPayloadItem>);

        dispatch(updateSelectedAssortments(selectedItems));
    };

    const toggleHandler = (detail: ToggleProps.ChangeDetail) =>{
        setHideAssigned(detail.checked);
    };

    const updateSkuHandler = async () => {
        try {
            const selectedAssortmentIds = apiRef?.current?.grid?.getSelectedRows().map(rowData => rowData.assortment_id);

            const {added, removed} = compareArrays(editingSKU.groupedAssortmentIDs, selectedAssortmentIds ?? []);

            const promises: any[] = [];

            if(added.length) {
                promises.push(updateSkuAssortments({
                    sku_id: editingSKU.sku_id,
                    assortment_ids: added ,
                    action: "add"
                }));
            }

            if(removed.length){
                promises.push(updateSkuAssortments({
                    sku_id: editingSKU.sku_id,
                    assortment_ids: removed ,
                    action: "delete"
                }));
            }

            setSavingSku(true);
            const response: any =  await Promise.allSettled(promises);

            response.forEach((res: any) =>{
                if(res.value?.data.statusCode !== 200){
                    throw res.value.data.error;
                }
            });
            addNotificationItem({
                type: "success",
                dismissible: true,
                content: "SKU updated successfully",
                id: "SKU_SAVE_SUCCESS"
            });

        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Failed to update SKU.",
                id: "SKU_UPDATE_FAILED"
            });
        }finally {
            setSavingSku(false);
            setEditSKUAssortmentsModalVisible(false);
        }
    };

    const customSettings = {
        className: 'ag-theme-alpine',
        readOnly: false,
        allowLocalFilters: true,
        filterId: 'assortments',
        onlyGrid: true,
        onRowSelection,
        getRowId
    };

    const columnDefs: (ColDef | ColGroupDef)[] = editSKUAssortmentsModalColDefs(versions);

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: columnDefs,
        rowData: [],
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        onGridReady,
        onModelUpdated
    });

    useEffect(() => {
        if (versions && assortments && assignedAssortments) {

            const map = Object.values(assortments).filter(item => {
                return `${versions[item.version_id]?.description}_${item.sku_type}` === `${editingSKU.description}_${editingSKU.sku_type}`;
            }).map(assortment => {
                const key = `${assortment.version_id}_${assortment.sku_type}_${assortment.channel}_${assortment.country ?? assortment.row}`;
                return {
                    ...assortment,
                    comment: assignedAssortments.includes(key) ? "Assortment already assigned to Existing SKU." : ''
                };
            });

            setGridOptions((prevState) => {
                return {
                    ...prevState,
                    rowData:hideAssigned ? map.filter((item) => !item.comment || editingSKU.groupedAssortmentIDs.includes(item.assortment_id)) : [...map]
                };
            });
        }
    }, [versions, assortments, assignedAssortments, hideAssigned]);

    return (
        <>
            <Modal
                onDismiss={() => setEditSKUAssortmentsModalVisible(false)}
                visible={visible}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setEditSKUAssortmentsModalVisible(false)}>Cancel</Button>
                            <Button variant="primary" onClick={updateSkuHandler} loading={savingSku} disabled={!Object.values(selectedAssortments).length}>Update SKU</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Edit SKU"
            >
                <SpaceBetween size='l'>
                    <div className='toggle'>
                        <Toggle
                            onChange={({ detail }) =>
                                toggleHandler(detail)
                            }
                            checked={hideAssigned}
                        >
                        Hide assigned assortments
                        </Toggle>
                    </div>
                    <GridTable
                        gridSettings={gridOptions}
                        customSettings={{
                            ...customSettings
                        }}
                    />
                </SpaceBetween>
            </Modal>
        </>
    );
}
