import { Button} from '@amzn/awsui-components-react';
import React, {useState} from 'react';
import { STATUS_CODES } from '../../constants/constants';
import DeactivateSkuModal from './deactivateSkuModal';

export default function DeactivateReactivateBtnRenderer(props: any) {

    const [deactivating, setDeactivating] = useState(false);
    const [reactivating, setReactivating] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const data = props.node.data;

    const handleDeactivate = async () => {
        try {
          setDeactivating(true);
          const response = await props.deactivateSKUHandler(data.sku_id);
          if (response.data.statusCode === STATUS_CODES.success) {
            props.addNotificationItem({
              type: "success",
              dismissible: true,
              content: "SKU deactivated successfully",
              id: "SKU_DEACTIVATE_SUCCESS"
            });
          } else {
            throw new Error(response.error);
          }
        } catch (error) {
          props.addNotificationItem({
            type: "error",
            dismissible: true,
            content: error,
            id: "SKU_DEACTIVATE_FAILED"
          });
        } finally {
          setDeactivating(false);
          handleModalClose();
        }
    };

    const handleReactivate = async () => {
      try {
        setReactivating(true);
        const response = await props.reactivateSKUHandler(data.sku_id);
        if (response.data.statusCode === STATUS_CODES.success) {
          props.addNotificationItem({
            type: "success",
            dismissible: true,
            content: "SKU reactivated successfully",
            id: "SKU_REACTIVATE_SUCCESS"
          });
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        props.addNotificationItem({
          type: "error",
          dismissible: true,
          content: error,
          id: "SKU_REACTIVATE_FAILED"
        });
      } finally {
        setReactivating(false);
      }
    };

    const handleModalOpen = () => {
        setShowModal(true);
    };
    
      const handleModalClose = () => {
        setShowModal(false);
    };

    const canDeactivate = ()=>{
      if (!deactivating) {
        if (props.hasDeactivateAccess) {
          return !data.is_deactivated && !data.requestingPN;
        } else if (props.hasWriteAccess) {
          return !data.pn && !data.is_deactivated && !data.requestingPN;
        } else {
          return false;
        };
      };
      return false;
    };

    const canReactivate = ()=>{
      if (!reactivating) {
        if (props.hasWriteAccess || props.hasDeactivateAccess) {
          return data.is_deactivated
        }
      };
      return false;
    }

    if (data.is_deactivated) {
        if (canReactivate()) {
          return(
            <div className="btn">
              <Button
                variant="primary"
                disabled={!canReactivate()}
                onClick={handleReactivate}
              >
                Reactivate
              </Button>
            </div>
          )
        } else {
          return "Deactivated";
        }
    } else {
      return (
        <div className='btn'>
          <Button
            variant="normal"
            disabled={!canDeactivate()}
            onClick={data.pn? handleModalOpen : handleDeactivate}
          >
            Deactivate
          </Button>
          <DeactivateSkuModal
            visible={showModal}
            onDismiss={handleModalClose}
            onDeactivate={handleDeactivate}
          />
        </div>
      );
    }
}
