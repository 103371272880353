import React, { useContext, useEffect, useState } from "react";
import { Box, Button, SpaceBetween } from "@cloudscape-design/components";
import { selectLocationData } from "../appLayout/appLayoutSlice";
import { useAppSelector } from "../../../redux/hooks";
import { 
    selectKeyDatesSectionServerData, 
    selectKeyDatesSectionClientData, 
    selectKeyDatesSectionModified, 
    selectStreetDates } from './keyDatesSlice';
import { useUpdateProgramSetupMutation, useUpdateProgramStageMutation } from '../../services/api';
import { 
    ALERT_MESSAGES, 
    STATUS_CODES, 
    TAB_ID_LABEL, 
    SK_MAPPING, 
    STAGES, 
    SEPERATOR, 
    MUTATION_METHODS, 
    USER_ACTION_ROLES, 
    STAGES_ACTION, 
    API_CONDITION_FAILED, 
    ATTRIBUTE_TYPE_NAMES } from '../../constants/constants';
import { IApiUpdateResponse } from "../../../Interfaces/interface";
import AlertMessageContext from "../../../common/Contexts/alertMessageContext";
import ScreenUtils from "../../utils/screenUtils";
import { ProgramAssignUsersModal } from "../userPolicy/program/assignUsersModal";
import { selectAttributesServerData, selectLoadingAPIs } from "../attributes/attributesSlice";

export const SubmitKeyDates = ({ setIsUpdateLoading, section, userRole }: any) => {

    const clientData = useAppSelector((state) => selectKeyDatesSectionClientData(state, section));
    const serverData = useAppSelector((state) => selectKeyDatesSectionServerData(state, section));
    const locationData = useAppSelector(selectLocationData);
    const streetDates = useAppSelector(selectStreetDates);
    const standardAttributes = useAppSelector(selectAttributesServerData).allAttributesValues;
    const { getPrimaryKey, generatePayload, getGraphQLPayload } = ScreenUtils;
    const isModified = useAppSelector((state) => selectKeyDatesSectionModified(state, section));
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const [updateProgramSetup, { isLoading: mutationLoading, data: mutationData, isError: mutationError }] = useUpdateProgramSetupMutation();
    const [updateProgramStage, { isLoading: updateStageLoading, data: updateStageData, isError: updateStageError }] = useUpdateProgramStageMutation();
    const keyDates = Object.values(serverData);
    const loadingAttrAPIs = useAppSelector(selectLoadingAPIs);
    const stage = keyDates?.length ? keyDates?.[0].Stage ?? STAGES.sandbox : STAGES.sandbox;
    const [modalVisible, setModalVisible] = useState(false);
    const [pendingStageUpdate, setPendingStageUpdate] = useState<string>();

    const requiredAttributesInStreetDates = standardAttributes && standardAttributes[ATTRIBUTE_TYPE_NAMES.country] ? standardAttributes[ATTRIBUTE_TYPE_NAMES.country] : []
    const requiredAttributesInOtherKeyDates: string[] = [];
    const { hasNullRequiredFields: hasNullRequiredFieldsStreetDates, nullRequiredFields: nullRequiredFieldsStreetDates } = ScreenUtils.checkRequiredFieldsNullOrUndefined(streetDates, requiredAttributesInStreetDates);
    const { hasNullRequiredFields: hasNullRequiredFieldsOtherKeyDates, nullRequiredFields: nullRequiredFieldsOtherKeyDates } = ScreenUtils.checkRequiredFieldsNullOrUndefined(keyDates, requiredAttributesInOtherKeyDates);

    const saveDisabled = !isModified;
    const submitDisabled = stage === STAGES.submitted || keyDates.length === 0 || (isModified && keyDates.length > 0);
    const approveDisabled = 
        isModified || 
        !ScreenUtils.isSandboxUpdated(clientData) || 
        loadingAttrAPIs.length > 0 ||
        (hasNullRequiredFieldsStreetDates && section === SK_MAPPING.streetDates) ||
        (hasNullRequiredFieldsOtherKeyDates && section === SK_MAPPING.otherDates);

    useEffect(() => {
        setIsUpdateLoading(mutationLoading);
    }, [mutationLoading]);

    useEffect(() => {
        if (!pendingStageUpdate?.length && !updateStageLoading) setIsUpdateLoading(updateStageLoading);
    }, [updateStageLoading]);

    const handleMutationResponse = (respData: any, hasError: boolean, method: string, isStageUpdate: boolean) => {
        if (respData?.errors || hasError) setError?.(ALERT_MESSAGES.updateFailure);
        if (respData?.data && respData?.data[method]) {
            const { statusCode, error }: IApiUpdateResponse = respData.data[method];
            if (statusCode === STATUS_CODES.success) {
                if (isStageUpdate && pendingStageUpdate?.length) {
                    updateStage(pendingStageUpdate, true);
                    setPendingStageUpdate('');
                } else setSuccess?.(ALERT_MESSAGES.updateSuccess);
            } else error?.includes(API_CONDITION_FAILED) ? setError?.(ALERT_MESSAGES.incorrectVersionFailure) : setError?.(ALERT_MESSAGES.updateFailure);
        }
    };

    useEffect(() => {
        handleMutationResponse(mutationData, mutationError, MUTATION_METHODS.updateProgramSetup, false);
    }, [mutationData, mutationError]);

    useEffect(() => {
        handleMutationResponse(updateStageData, updateStageError, MUTATION_METHODS.updateProgramStage, true);
    }, [updateStageData, updateStageError]);

    const save = () => {
        setIsUpdateLoading(true);
        const allValuesToSubmit = Object.values(clientData).filter(obj => {
            if (obj.Action) return obj;
        });
        if (allValuesToSubmit.length > 0) {
            const payload = generatePayload(allValuesToSubmit);
            payload ? handleUpdate(payload) : setIsUpdateLoading(false);
        }
    };

    const submitAndAppove = () => {
        updateStage(STAGES_ACTION.submitted, false);
        setPendingStageUpdate(STAGES_ACTION.approved);
    };

    const updateStage = async (stage: string, invalidateTag: boolean) => {
        setIsUpdateLoading(true);
        const payload = {
            InputGroup: TAB_ID_LABEL.keyDates + SEPERATOR + section,
            PK: getPrimaryKey(locationData, ''),
            Action: stage
        };
        await updateProgramStage({ data: getGraphQLPayload(payload), invalidate: invalidateTag }).unwrap();
    };

    const handleUpdate = async (mutationPayload: string) => {
        console.log(mutationPayload);// console added for beta testing
        await updateProgramSetup(mutationPayload).unwrap();
    };

    return (
        <>
            <SpaceBetween direction="horizontal" size="s">
                {section === SK_MAPPING.streetDates && locationData.fpn.id && (
                    ScreenUtils.renderMissingFieldsWarningMessage({
                        hasNullFields: hasNullRequiredFieldsStreetDates,
                        nullFields: nullRequiredFieldsStreetDates
                    })
                )}
                {section === SK_MAPPING.otherDates && (
                    ScreenUtils.renderMissingFieldsWarningMessage({
                        hasNullFields: hasNullRequiredFieldsOtherKeyDates,
                        nullFields: nullRequiredFieldsOtherKeyDates
                    })
                )}
                <Box display="block" >
                <Box float='right'>
                    {!(section === SK_MAPPING.streetDates && !locationData.fpn.id) &&
                        <SpaceBetween direction="horizontal" size="s"> 
                            {USER_ACTION_ROLES.save.includes(userRole) && <Button className="bg-primary" disabled={saveDisabled} ariaLabel="save-key-dates" onClick={save} variant="primary">Save</Button>}
                            {USER_ACTION_ROLES.approve.includes(userRole) && <Button className="bg-primary" disabled={approveDisabled} variant="primary" onClick={() => submitAndAppove()} >Push to WIP</Button>}
                            {USER_ACTION_ROLES.submit.includes(userRole) && <Button className="bg-primary" disabled={submitDisabled} variant="primary" onClick={() => updateStage(STAGES_ACTION.submitted, true)} >Submit for approval</Button>}
                        </SpaceBetween>
                    }
                </Box>
                </Box>
                {USER_ACTION_ROLES.assignUser.includes(userRole) && !locationData.fpn.id && <Button onClick={() => setModalVisible(true)} >Assign users</Button>}

            </SpaceBetween>
            {modalVisible && <ProgramAssignUsersModal
                visible={modalVisible}
                setVisible={setModalVisible}
                tab={TAB_ID_LABEL.keyDates}
                section={section}
            />}
        </>

    );

};
