import { AxiosError } from 'axios';
import Endpoints from '../../../constants/endpoints';
import xhr from '../../common/Auth/xhr';
import Queries from './queries';
import { API_METHODS, MUTATION_METHODS } from '../constants/constants';
import ScreenUtils from '../utils/screenUtils';
import { verityApi as verityCoreApi } from '../../redux/api';

const getFPNDetails = async (PK: string) => {
    try {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getProgramOverview(PK));
        if (response.data?.data?.get_prog_overview?.body) return ScreenUtils.formatProgramAPIData(response.data?.data?.get_prog_overview.body);
        return response;
    } catch (axiosError) {
        const err = axiosError as AxiosError;
        return { error: { status: err.response?.status, data: err.response?.data } };
    }
};

// This file will be split into smaller files 
export const verityApi = verityCoreApi.injectEndpoints({
    endpoints: (build) => ({
        getProgramDetails: build.query({
            query: (PK: string) => ({ method: API_METHODS.post, data: Queries.getProgramOverview(PK) }),
            providesTags: (result, error, PK) => [{ type: 'ProgramSetup', PK }],
            transformResponse: (response: any) => {
                if (response.data?.data?.get_prog_overview?.body) return ScreenUtils.formatProgramAPIData(response.data?.data?.get_prog_overview.body);
                return response.data;
            }
        }),
        updateProgramSetup: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.updateProgramSetup) }),
            invalidatesTags: () => [{ type: 'ProgramSetup' }],
            transformResponse: (response: any) => response.data
        }),
        updateAndApproveProgram: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.saveApproveProgram) }),
            invalidatesTags: () => [{ type: 'ProgramSetup' }],
            transformResponse: (response: any) => response.data
        }),
        updateProgramStage: build.mutation({
            query: ({data}) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.updateProgramStage) }),
            invalidatesTags: (result, error, { invalidate }) => invalidate ? [{ type: 'ProgramSetup' }] : [] ,
            transformResponse: (response: any) => response.data
        }),
        updateFPNs: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.updateProgramSetup) }),
            transformResponse: (response: any) => response.data
        }),
        updateRPN: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.updateRPN) }),
            invalidatesTags: () => [{ type: 'ProductHierarchy' }, { type: 'VerityProgramDetails' }],
            transformResponse: (response: any) => response.data
        }),
        createRPN: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQueryWithCodes(data, MUTATION_METHODS.createRPN) }),
            invalidatesTags: () => [{ type: 'ProductHierarchy' }],
            transformResponse: (response: any) => response.data
        }),
        createFPN: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQueryWithCodes(data, MUTATION_METHODS.postFPN) }),
            invalidatesTags: () => [{ type: 'ProductHierarchy' }, { type: 'FPNsByRPNList' }],
            transformResponse: (response: any) => response.data
        }),
        createProgramPPLS: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.pplsCreate) }),
            invalidatesTags: () => [{ type: 'VerityProgramDetails' }],
            transformResponse: (response: any) => response.data
        }),
        createPLAttributes: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.createPLAttributes) }),
            transformResponse: (response: any) => response.data
        }),
        getRPNDetails: build.query({
            query: (id: number) => ({ method: API_METHODS.post, data: Queries.getRPNDetails(id) }),
            providesTags: (result, error, id) => [{ type: 'VerityProgramDetails', id }],
            transformResponse: (response: any) => response.data
        }),
        getFPNDetails: build.query({
            query: (id: number) => ({ method: API_METHODS.post, data: Queries.getFPNDetails(id) }),
            providesTags: (result, error, id) => [{ type: 'VerityProgramDetails', id }],
            transformResponse: (response: any) => response.data
        }),
        getFPNsByRPN: build.query({
            query: (id: number) => ({ method: API_METHODS.post, data: Queries.getFPNsByRPN(id) }),
            providesTags: (result, error, id) => [{ type: 'FPNsByRPNList', id }],
            transformResponse: (response: any) => response.data
        }),
        getAttributeList: build.query<any, void>({
            query: () => ({ method: API_METHODS.post, data: Queries.getAttributesLists() }),
            transformResponse: (response: any) => response.data.data?.get_attribute_list ? response.data.data.get_attribute_list.body : null
        }),
        getAttributeType: build.query<any, void>({
            query: () => ({ method: API_METHODS.post, data: Queries.getAttributesTypes() }),
            transformResponse: (response: any) => response.data.data?.get_attribute_type ? response.data.data.get_attribute_type.body : null
        }),
        getPLAttributeType: build.query({
            query: (pl: number) => ({ method: API_METHODS.post, data: Queries.getPLAttributesTypes(pl)}),
            transformResponse: (response: any) => response.data.data?.get_attribute_pl_type ? response.data.data.get_attribute_pl_type.body : null
        }),
        getCostCenterCode: build.query<any, void>({
            query: () => ({ method: API_METHODS.post, data: Queries.getCostCenterCodes() }),
            keepUnusedDataFor: 5000,
            transformResponse: (response: any) => response.data.data?.get_cc ? response.data.data.get_cc.body : null
        }),
        getFPNsDetail: build.query({
            queryFn: async (keys: string[]) => {
                const promises = keys.map((PK) => getFPNDetails(PK));
                const results = await Promise.all(promises);
                return { data: results };
            }
        })
    })
});

export const { 
    useGetPLAttributeTypeQuery,
    useGetProgramDetailsQuery,
    useGetAttributeListQuery,
    useGetAttributeTypeQuery,
    useGetFPNDetailsQuery,
    useGetFPNsByRPNQuery,
    useGetRPNDetailsQuery,
    useGetCostCenterCodeQuery,
    useUpdateProgramSetupMutation,
    useUpdateAndApproveProgramMutation,
    useUpdateProgramStageMutation,
    useUpdateFPNsMutation,
    useUpdateRPNMutation,
    useCreatePLAttributesMutation,
    useCreateRPNMutation,
    useCreateFPNMutation,
    useGetFPNsDetailQuery,
    useCreateProgramPPLSMutation } = verityApi;