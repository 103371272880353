import { UserValidationMessages } from 'src/constants/constants';
import { API, Auth } from 'aws-amplify';

export interface PAPIUserInfo {
    personId: string;
    lastName: string;
    firstName: string;
    login: string;
    badgeColor: string;
    businessTitle: string;
    preferredEmail?: any;
    primaryEmail: string;
    primaryPhone: string;
    isManager: boolean;
    managerPersonId: string;
    managerLogin: string;
    managerName: string;
    jobProfileId: string;
    jobProfileName: string;
    costCenterId: string;
    extendedCostCenterId: string;
    costCenterName: string;
    extendedCostCenterName: string;
    companyId: string;
    companyUdmId: string;
    companyName: string;
    jobStatus: string;
    employeeStatus: string;
    workspace: string;
    tenureDays: number;
    serviceDate: number;
    legacyEmployeeId: string;
    isPrehire: boolean;
    pastTenureDays: number;
}

export enum PAPIEmployeeJobStatus {
    Active = 'A',
    Inactive = 'I'
}

export interface ValidateUserAliasResponse {
    isValid: boolean;
    validationMessage: string;
    userInfo?: {
        login: string;
        firstName: string;
        lastName: string;
        costCenterName: string;
        businessTitle: string;
    };
}

/**
 * Generates parameters for PAPI request.
 *
 * @param userAlias - The user alias to be validated.
 * @returns The parameters object for the PAPI request.
 */
const getPapiParams = (userAlias: string) => ({
    response: true,
    queryStringParameters: {
        login: userAlias,
        expandOptions: ''
    }
});

/**
 * Validate user alias by checking
 * authentication status and employment status via People API (PAPI).
 * 
 * @param userAlias - The user alias to validate
 * @returns A Promise that resolves to a ValidateUserAliasResponse object containing:
 *          - isValid: boolean indicating if the user is valid (active)
 *          - validationMessage: Status message indicating validation result
 *          - userInfo?: Object containing user's login, firstName, and lastName (if validation successful)
 * 
 * @throws Will not throw errors, but handles them internally:
 *         - Authentication errors (NotAuthorizedException)
 *         - API errors when fetching employee information
 * 
 * @example
 * try {
 *   const validation = await validateUserAlias('jdoe123');
 *   if (validation.isValid) {
 *     console.log('Valid user:', validation.userInfo);
 *   }
 * } catch (error) {
 *   console.error('Validation failed:', error);
 * }
 */
export const validateUserAlias = async (
    userAlias: string
): Promise<ValidateUserAliasResponse> => {
    try {
        // Ensure user is authenticated first
        await Auth.currentAuthenticatedUser();

        const response = await API.get('PAPI', '/employee', getPapiParams(userAlias));
        const employeeBasicInfo: PAPIUserInfo = response.data.basicInfo;

        return {
            isValid: employeeBasicInfo.jobStatus === PAPIEmployeeJobStatus.Active,
            validationMessage:
                employeeBasicInfo.jobStatus === PAPIEmployeeJobStatus.Active
                    ? UserValidationMessages.UserIsValid
                    : UserValidationMessages.UserIsInactive,
            userInfo: {
                login: employeeBasicInfo.login,
                firstName: employeeBasicInfo.firstName,
                lastName: employeeBasicInfo.lastName,
                costCenterName: employeeBasicInfo.costCenterName,
                businessTitle: employeeBasicInfo.businessTitle
            }
        };
    } catch (error: any) {
        console.error('PAPI validation error:', error);

        if (error.name === 'NotAuthorizedException') {
            console.error('Authentication error:', error);
        }

        return {
            isValid: false,
            validationMessage: UserValidationMessages.UserAliasIsInvalid
        };
    }
};

/**
 * Validates multiple user aliases concurrently by checking authentication status 
 * and employment status via People API (PAPI).
 * 
 * @param userAliases - Array of user aliases to validate
 * @returns A Promise that resolves to an array of ValidateUserAliasResponse objects, where each object contains:
 *          - isValid: boolean indicating if the user is valid (active)
 *          - validationMessage: Status message indicating validation result
 *          - userInfo?: Object containing user's login, firstName, lastName, costCenterName, and businessTitle (if validation successful)
 * 
 * @throws Will not throw errors, but handles them internally:
 *         - Authentication errors (NotAuthorizedException)
 *         - API errors when fetching employee information
 * 
 * @example
 * try {
 *   const validations = await validateMultipleUserAliases(['jdoe123', 'msmith456']);
 *   validations.forEach(validation => {
 *     if (validation.isValid) {
 *       console.log('Valid user:', validation.userInfo);
 *     }
 *   });
 * } catch (error) {
 *   console.error('Validation failed:', error);
*/
export const validateMultipleUserAliases = async (
    userAliases: string[]
): Promise<ValidateUserAliasResponse[]> => {
    try {
        const promises = userAliases.map((alias) => validateUserAlias(alias));
        
        return await Promise.all(promises);
        
    } catch (error: any) {
        console.error('Multiple user validation error:', error);
        
        return userAliases.map(() => ({
            isValid: false,
            validationMessage: UserValidationMessages.UserAliasIsInvalid
        }));
    }
};