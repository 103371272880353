import { getAuthConfig } from "./AuthConfiguration";
const AmazonCognitoIdentity = require("amazon-cognito-auth-js");
import { Amplify, Auth }  from "aws-amplify";
import {
  CognitoUser,
  CognitoUserSession,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js';
import Time from "../Utils/time";

const CognitoAuth = AmazonCognitoIdentity.CognitoAuth;

export function getAuth() {
    const authConfig = getAuthConfig();
    const auth = new CognitoAuth(authConfig);
    // Make sure you turn on OAuth2 Authorization Code Grant flow
    auth.useCodeGrantFlow();
    return auth;
}

/**
 * Ensures the user is authenticated.
 * If authenticated, return auth object. Otherwise force authentication.
 * Auth object will be used to call API gateway
 */
export const ensureAuthenticated = async() => {
    const authConfig = getAuthConfig();
    Amplify.configure({
      Auth: {
        identityPoolId: authConfig.identityPoolId,
        region: authConfig.region,
        userPoolId: authConfig.userPoolId,
        userPoolWebClientId: authConfig.userPoolWebClientId,
        mandatorySignIn: authConfig.mandatorySignIn,
        oauth: {
          domain: authConfig.oauth.domain,
          scope: authConfig.oauth.scope,
          redirectSignIn: authConfig.oauth.redirectSignIn,
          redirectSignOut: authConfig.oauth.redirectSignOut,
          responseType: authConfig.oauth.responseType
        }
      },
      API: {
        endpoints: [
          {
            name: authConfig.papi.name,
            endpoint: authConfig.papi.endpoint,
            region: authConfig.region,
          }
        ]
      }
    });

    const getUserNameFromCognitoResponse = (cognitoUsername: string) => {
        return cognitoUsername ? cognitoUsername.split("_")[1] : "";
      };

    let userName;
    try {
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        Auth.federatedSignIn({ customProvider: "AmazonFederate" })
      } finally {
        const userData = await Auth.currentAuthenticatedUser();
        userName = getUserNameFromCognitoResponse(userData.username);
        sessionStorage.setItem("userName", userData.getUsername());
      }
      return userName;
}

export const getValidToken = async (refreshing: boolean)=>{
  const session = await Auth.currentSession();
  const idToken = session.getIdToken();
  let jwtToken = idToken.getJwtToken();
  const tokenExpirationMs = Time.seconds(idToken.getExpiration());

  /*
 * We refresh the id token when
 * 1) there are no ongoing refreshes, AND
 * 2) the token is less than 10 minutes away from expiring (or has expired)
 */
  const shouldRefreshIdToken =  !refreshing && Date.now() + Time.minutes(10) >= tokenExpirationMs;

  if (!shouldRefreshIdToken) {
      sessionStorage.setItem('token', jwtToken);
      return jwtToken;
  }

  const cognitoRefreshSessionPromise = (
      cognitoUser: CognitoUser,
      refreshToken: CognitoRefreshToken
    ): Promise<CognitoUserSession> =>
      new Promise((resolve, reject) => {
        cognitoUser.refreshSession(refreshToken, (err, session) => {
          if (err) {
            reject(err);
          }
          resolve(session);
        });
      });

  // Attempt the refresh
  try{
      console.log('refreshing id token.');
      refreshing = true;
      const user = await Auth.currentAuthenticatedUser();
      const refreshedSession = await cognitoRefreshSessionPromise(user, session.getRefreshToken());
      refreshing = false;
      jwtToken = refreshedSession.getIdToken().getJwtToken();
      sessionStorage.setItem('token', jwtToken);
      return jwtToken;
  }catch(sessionRefreshError){
      // Session refresh failure is only fatal if the token has expired
      console.error(sessionRefreshError)
  }
  return jwtToken;
}

export const getUserId = () => sessionStorage.getItem('userName')?.split('_')[1] ?? '';