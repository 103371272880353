import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { Multiselect, FormField, MultiselectProps } from "@cloudscape-design/components";
import { minUserLength } from 'src/constants/constants';
import { IApiUserDetail } from 'src/components/RPT/interfaces/interfaces';
import { validateUserAlias, ValidateUserAliasResponse } from 'src/utils/papi-service';

const i18nStrings = {
    tokenLimitShowFewer: 'Show fewer chosen options',
    tokenLimitShowMore: 'Show more chosen options'
};

const deselectAriaLabel = (option: MultiselectProps.Option) => {
    const label = option?.value || option?.label;
    return label ? `Deselect ${label}` : 'no label';
};

export interface IUserMultiselectProps {
    name: string,
    selectedValues: MultiselectProps.Option[],
    readOnly: boolean,
    label?: string,
    action: (value:MultiselectProps.Option[], name:string) => void
}

export const getUserOption = (user: IApiUserDetail) => {
    return {
        label: user.employee_name + ' (' + user.employee_login + ')',
        value: user.employee_login,
        description: user.department_name + ', ' + user.business_title
    } as MultiselectProps.Option;
};

export const UserSearchMultiselect = ({ name, selectedValues, readOnly, label, action }: IUserMultiselectProps) => {
    const [options, setOptions] = useState<MultiselectProps.Option[]>([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [errorLoading, serErrorLoading] = useState(false);

    const fetchUserDetails = useCallback(debounce(async (user: string) => {
        if (user.length >= minUserLength) {
            setLoadingOptions(true);
            try {
                const response: ValidateUserAliasResponse = await validateUserAlias(user);

                if (!response.isValid || !response.userInfo) {
                    setOptions([]);
                    serErrorLoading(true);
                } else {
                    setOptions(() => {
                        return [{
                            label: `${response?.userInfo?.firstName} ${response?.userInfo?.lastName} (${response?.userInfo?.login})`,
                            value: response?.userInfo?.login,
                            description: `${response?.userInfo?.costCenterName}, ${response?.userInfo?.businessTitle}`
                        }];
                    });
                }
            } catch (error) {
                serErrorLoading(true);
                setOptions([]);
            } finally {
                setLoadingOptions(false);
            }
        } else {
            setOptions([]);
        }
    }, 500), []);


    return (
        <FormField stretch label={label} >
            <Multiselect
                name={name}
                ariaLabel="user-search"
                selectedOptions={selectedValues}
                filteringType="manual"
                filteringPlaceholder="Enter user alias"
                filteringAriaLabel="Filtering aria label"
                placeholder="Select user"
                loadingText="Loading users"
                errorText="Error fetching results."
                recoveryText="Retry"
                finishedText='End of all results'
                empty="No users found"
                options={options}
                tokenLimit={2}
                deselectAriaLabel={deselectAriaLabel}
                i18nStrings={i18nStrings}
                statusType={errorLoading ? "error" : loadingOptions ? "loading" : "finished"}
                disabled={readOnly}
                onLoadItems={({ detail : { filteringText }}) => {
                    fetchUserDetails(filteringText);
                }}
                onChange={({ detail: { selectedOptions } }) => {
                    action([...selectedOptions], name);
                }}
            />
        </FormField >
    );
};