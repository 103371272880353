import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { GridTable } from 'src/components/common/GridTable/GridTable';
import { useAppDispatch, useAppSelector } from 'src/components/redux/hooks';
import { getAssortments } from '../assortment/assortmentSlice';
import { selectVersions } from '../versions/versionSlice';
import { Alert, Box, Button, FlashbarProps, Modal, SpaceBetween, Toggle } from '@amzn/awsui-components-react';
import { useUpdateSkuVersionTypeMutation } from '../../services/apis/api';
import { getAssignedAssortments, getSelectedAssortments, updateSelectedAssortments } from './skuSlice';
import { STATUS_CODES } from '../../constants/constants';
import { assortment } from '../../interfaces/assortmentInterface';
import { editSKUVersionModalColDefs } from './columnDefs';

export default function EditSkuVersionModal({ visible, setEditSKUVersionModalVisible, addNotificationItem, editingSKU }: {
    visible: boolean,
    setEditSKUVersionModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    addNotificationItem: (notificationItem: FlashbarProps.MessageDefinition) => void,
    editingSKU: any
}) {    

    // hooks
    const dispatch = useAppDispatch();
    const [ updateSkuVersionType ] = useUpdateSkuVersionTypeMutation();

    //from store
    const assignedAssortments = useAppSelector(getAssignedAssortments);
    const { assortments } = useAppSelector(getAssortments);
    const versions = useAppSelector(selectVersions);
    const selectedAssortments = useAppSelector(getSelectedAssortments);

    //state
    const [savingSku, setSavingSku] = useState(false);
    const [hideAssigned, setHideAssigned] = useState(true);
    const [selectedRow, setSelectedRow] = useState<assortment | null>(null);


    const onRowSelection = (selectedRows: assortment[]) => {
        if (selectedRows.length > 0) {
            const firstSelectedRow = selectedRows[0]; // We'll use the first row for version_id and sku_type
            setSelectedRow(firstSelectedRow);
    
            const selectedItem = {
                [`${firstSelectedRow.version_id}_${firstSelectedRow.sku_type}`]: {
                    sku_id: editingSKU.sku_id,
                    version_id: firstSelectedRow.version_id,
                    sku_type: firstSelectedRow.sku_type,
                    assortment_ids: selectedRows.map(row => row.assortment_id)
                }
            };
    
            dispatch(updateSelectedAssortments(selectedItem));
        } else {
            setSelectedRow(null);
            dispatch(updateSelectedAssortments({}));
        }
    };

    const updateSkuVersionHandler = async () => {
        try {
            const assortmentList = Object.values(selectedAssortments);
            if(assortmentList.length){
                setSavingSku(true);
                const payload = {
                    input: assortmentList
                };
                const response =  await updateSkuVersionType(assortmentList[0]).unwrap();

                if (response.statusCode === STATUS_CODES.success) {
                    addNotificationItem({
                        type: "success",
                        dismissible: true,
                        content: "SKU updated successfully",
                        id: "SKU_SAVE_SUCCESS"
                    });
                } else {
                    throw new Error(response.error);
                }
            }
        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Failed to update SKU.",
                id: "SKU_SAVE_FAILED"
            });
        }finally {
            setSavingSku(false);
            setEditSKUVersionModalVisible(false);
        }
    };

    const customSettings = {
        className: 'ag-theme-alpine',
        readOnly: false,
        allowLocalFilters: true,
        filterId: 'assortments',
        onlyGrid: true,
        onRowSelection
    };

    const columnDefs: (ColDef | ColGroupDef)[] = editSKUVersionModalColDefs(versions);

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: columnDefs,
        rowData: [],
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        isRowSelectable: (params) => (params.data.version_id !== editingSKU.version_id || params.data.sku_type !== editingSKU.sku_type)
    });

    useEffect(() => {
        if (versions && assortments && assignedAssortments) {
            const map = Object.values(assortments).map(assortment => {
                const key = `${assortment.version_id}_${assortment.sku_type}_${assortment.channel}_${assortment.country ?? assortment.row}`;
                return {
                    ...assortment,
                    comment: assignedAssortments.includes(key) ? "Assortment already assigned to existing SKU." : ''
                };
            });
    
            setGridOptions((prevState) => {
                // Create the current SKU row
                const currentSkuRow = {
                    ...editingSKU,
                    comment: "Current SKU to be updated."
                };
    
                let filteredData = hideAssigned 
                    ? map.filter(item => !item.comment && (item.version_id !== editingSKU.version_id || item.sku_type !== editingSKU.sku_type)) 
                    : map.filter(item => (item.version_id !== editingSKU.version_id || item.sku_type !== editingSKU.sku_type));
    
                if (selectedRow) {
                    filteredData = filteredData.filter(item => 
                        item.version_id === selectedRow.version_id && 
                        item.sku_type === selectedRow.sku_type
                    );
                }
    
                // Sort the filtered data
                const sortedData = filteredData.sort((a, b) => {
                    if (a.version_id !== b.version_id) {
                        return a.version_id - b.version_id;
                    }
                    return a.sku_type.localeCompare(b.sku_type);
                });
    
                // Add the current SKU row at the top
                const finalData = [currentSkuRow, ...sortedData];
    
                return {
                    ...prevState,
                    rowData: finalData
                };
            });
        }
    }, [versions, assortments, assignedAssortments, hideAssigned, selectedRow, editingSKU]);

    return (
        <>
            <Modal
                onDismiss={() => setEditSKUVersionModalVisible(false)}
                visible={visible}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setEditSKUVersionModalVisible(false)}>Cancel</Button>
                            <Button variant="primary" onClick={ updateSkuVersionHandler } loading={savingSku} disabled={!Object.values(selectedAssortments).length}>Update SKU Version and SKU Type</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Edit SKU Version, Sku Type and Assortments"
            >
                <SpaceBetween size='l'>
                    {editingSKU.pn && (
                        <Alert type="warning" header="Part Number Exists">
                            The SKU has 53 Part Number {editingSKU.pn} created. 
                            Please align the changes with TownSend{' '}
                            <a href={`https://devices.plm.aws.com/townsend/part/detail/${editingSKU.pn}?tab=overview-tab`} target="_blank" rel="noopener noreferrer">
                                https://devices.plm.aws.com/townsend/part/detail/{editingSKU.pn}?tab=overview-tab
                            </a>
                        </Alert>
                    )}
                    <div className='toggle'>
                        <Toggle
                            onChange={({ detail }) =>
                                setHideAssigned(detail.checked)
                            }
                            checked={hideAssigned}
                        >
                        Hide assigned assortments
                        </Toggle>
                    </div>
                    <GridTable
                        gridSettings={gridOptions}
                        customSettings={{
                            ...customSettings
                        }}
                    />
                </SpaceBetween>
            </Modal>
        </>
    );
}