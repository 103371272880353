import { loadAccessInfo, getWebSocketUrl } from './WebSocketAuth'
import { Signer } from 'aws-amplify'
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from 'websocket'
import { v4 as uuidv4 } from 'uuid'
import { AsyncResponse } from './WebSocketHandler'
	 
enum Route {
	// Create new instances of the same class as static attributes
	Disconnect = "disconnect",
	Process = "process",
	Progress = "progress",
	Refresh = "refresh",
	Heartbeat = "heartbeat",
	Cancel = "cancel",
	Status = "status",
    Acknowledge ="acknowledge",
}
	 
export interface User {
	user: string,
	email: string
}

export interface MessageBody {
    resource?: string,
    path?: string,
    queryStringParameters?: object,
    serviceName?: string,
    equivalentHttpMethod?: string,
    processId?: string,
    appId?: string,
    currentConnectionId?: string,
}
      
export interface MesssagePayload {
    action: string,
    message?: MessageBody,
    user?: User
}
      
export interface MessageEvent {
    data: string
}
      
export interface AccessInfo {
    access_key: string,
    secret_key: string,
    session_token: string
}

export interface responseHook {
    holder: object,
    setter: Function,
}
      
export class WebSocketClient {
    private static instance: WebSocketClient;
    private static connectionOpen = false
    private static wsConnection: W3CWebSocket
    accessInfo
    token
    queueRequest = new Array
    // map contains any response coming back from async service for the given processId
    queueResponse = new Map()
    // map contains hook for the given processId. Calling component would pass this hook when invokes process
    queueHook = new Map()
    // Array contains any unacknowledged completed processes found under given user
    queueCompletedResponse = new Array
    heartbeatInterval!: number
    currentConnectionId = '';
    user = '';
    email = '';
      
    constructor (accessInfo: AccessInfo, token: String) {
        if (typeof accessInfo === 'undefined') {
            throw new Error('Cannot be called directly')
        }
        this.accessInfo = accessInfo
        this.token = token
        if (!WebSocketClient.instance) {
            WebSocketClient.instance = this
        }
        return WebSocketClient.instance
    }
    start () {
        if (!this.isConnectionValid()) {
            const wssUrl = getWebSocketUrl(this.token)
            const signedWssUrl = Signer.signUrl(wssUrl, this.accessInfo)
            WebSocketClient.wsConnection = new W3CWebSocket(signedWssUrl)
            WebSocketClient.wsConnection.onerror = this.onWebsocketError
            WebSocketClient.wsConnection.onopen = this.onWebsocketOpen
            WebSocketClient.wsConnection.onclose = this.onWebsocketClose
            WebSocketClient.wsConnection.onmessage = (event: IMessageEvent) => this.onWebsocketMessage(event)
        }
    }
        
    static async build () {
        try {
            if (WebSocketClient.instance) {
                return WebSocketClient.instance
            }
            const accessInfoTuple: Promise<[AccessInfo, String]> = await loadAccessInfo() as Promise<[AccessInfo, String]>
            const [accessInfo, token] = await accessInfoTuple
            return new WebSocketClient(accessInfo, token) 
        } catch (err) {
            console.log('could not construct WebSocketClient', err)
            return undefined
        }
    }
        
    process (resource: string, equivalentHttpMethod: string, serviceName: string, responseArray: AsyncResponse, setResponse: (response: AsyncResponse) => void, user:string, email: string, body?: string) {
        this.setUser(user, email)
        const processId = uuidv4()
        this.queueResponse.set(processId, responseArray)
        this.queueHook.set(processId, setResponse)
        setResponse(responseArray)
        const payload = WebSocketClient.toMessagePayload(Route.Process, this.toProcessPayload(resource, equivalentHttpMethod, serviceName, processId, body), user, email)
        this.handleRequest(payload)
        return {
            processId: processId
        }
    }

    refresh () {
        const payload = WebSocketClient.toMessagePayload(Route.Refresh, this.toRefreshPayload(), this.user, this.email)
        this.handleRequest(payload)
    }

    updateSetResponse (processId: string, setResponse: (response: AsyncResponse) => void) {
        this.queueHook.set(processId, setResponse)
    }

    getResponse (processId: string) {
        return this.queueResponse.get(processId)
    }

    acknowledge (processId: string) {
        const payload = WebSocketClient.toMessagePayload(Route.Acknowledge, this.toAcknowledgePayload(processId))
        this.handleRequest(payload)
        this.queueResponse.delete(processId)
        this.queueHook.delete(processId)
    }
        
    cancel (processId: string) {
        const payload = WebSocketClient.toMessagePayload(Route.Cancel, this.toCancelPayload(processId))
        this.handleRequest(payload)
    }
        
    status (processId: string) {
        const payload = WebSocketClient.toMessagePayload(Route.Status, this.toStatusPayload(processId))
        if (!WebSocketClient.connectionOpen && WebSocketClient.wsConnection === undefined) {
            this.start()
        } else if (!WebSocketClient.connectionOpen) {
            console.log('cannot proceed with status as awaiting to connect')
        } else {
            try {
                this.sendMessage(payload)
            } catch (err) {
                console.log(err)
            }
        }
    }

    close () {
        return this.closeConnection()
    }
        
    async heartbeat () {
        const payload = WebSocketClient.toMessagePayload(Route.Heartbeat)
        this.heartbeatInterval = setInterval(this.sendHeartbeat, 30000, WebSocketClient.wsConnection, payload)
    }
        
    sendHeartbeat (wsConnection: W3CWebSocket, payload: Object) {
        try {
            wsConnection.send(JSON.stringify(payload))
        } catch (err) {
            console.log(err)
        }
    }
        
    handleRequest (payload: MesssagePayload) {
        if (!WebSocketClient.connectionOpen && WebSocketClient.wsConnection === undefined) {
            this.start()
            this.queueRequest.push(payload)
        } else {
            try {
                this.sendMessage(payload)
            } catch (err) {
                this.queueRequest.push(payload)
                console.log(err)
            }
        }
    }

    async sendMessage (payload: MesssagePayload) {
        try {
            while(!this.isConnectionValid()) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
            WebSocketClient.wsConnection.send(JSON.stringify(payload))
        } catch (err) {
            console.log(err)
        }
    }
        
    sendMessages () {
        while (this.queueRequest.length > 0) {
            const payload = this.queueRequest.shift()
            if (payload) {
                this.sendMessage(payload)
            }
        }
    }

    closeConnection () {
        WebSocketClient.connectionOpen = false
        WebSocketClient.wsConnection.close()
        this.clearConnectionInSession()
    }
        
    onWebsocketClose = () => {
        this.start()
    }
        
    onWebsocketOpen = () => {
        WebSocketClient.connectionOpen = true
        this.refresh()
        this.sendMessages()
        this.heartbeat()
    }
        
    onWebsocketMessage = (event: IMessageEvent) => {
        if (event.data) {
            const response = JSON.parse(event.data as string)
            if (response.processInfo) {
                console.log(response);
                const action = response.processInfo.action 
                if (action === Route.Process) {
                    this.queueResponse.get(response.processInfo.processId).response = response.response
                } else if (action === Route.Status) {
                    this.queueResponse.get(response.processInfo.processId).latestStatus = response.response.progress
                } else if (action === Route.Refresh) {
                    this.currentConnectionId = response.processInfo.connectionId
                    this.queueCompletedResponse.push(response)
                }
                if (response.processInfo.processId) {
                    this.queueResponse.set(response.processInfo.processId, JSON.parse(JSON.stringify(this.queueResponse.get(response.processInfo.processId))))
                    this.queueHook.get(response.processInfo.processId)(this.queueResponse.get(response.processInfo.processId))
                }
            }            
        }
    }      

    onWebsocketError = (event: object) => {
        console.log('websocket error: ', event)
    }
        
    static toMessagePayload = (route: string, message?: MessageBody, user?: string, email?:string) => {
        if (typeof user !== 'undefined' && typeof email !== 'undefined') {
            return {
                action: route,
                message: message,
                user: {
                    user: user,
                    email: email,
                }
            }
        } else {
            return {
                action: route,
                message: message
            }
        }
    }
        
    toProcessPayload = (resource: string, equivalentHttpMethod: string, serviceName: string, processId: string, body?: any) => {
        const pathWithParams = resource.split('?')
        return {
            resource: resource,
            path: pathWithParams[0],
            queryStringParameters: (pathWithParams.length > 1) ? this.toParameters(pathWithParams[1]) : undefined,
            postBody: JSON.stringify(body),
            serviceName: serviceName,
            equivalentHttpMethod: equivalentHttpMethod,
            processId: processId,
            appId: "{{AppId}}",
        }
    }
        
    toCancelPayload = (processId: string) => {
        return {
            processId: processId
        }
    }

    toStatusPayload = (processId: string) => {
        return {
            processId: processId
        }
    }

    toAcknowledgePayload = (processId: string) => {
        return {
            processId: processId
        }
    }

    toRefreshPayload = () => {
        return {
            currentConnectionId: this.currentConnectionId,
        }
    }
        
    isConnectionValid = () => {
        if (WebSocketClient.wsConnection && WebSocketClient.wsConnection.readyState && WebSocketClient.wsConnection.readyState === WebSocket.OPEN && WebSocketClient.connectionOpen) {
            return true
        }
        return false
    }
        
    clearConnectionInSession = () => {
        WebSocketClient.connectionOpen = false
        clearInterval(this.heartbeatInterval)
    }
        
    toParameters = (parametersStr: string) => {
        const parameters = parametersStr.split('&')
        const parametersObject: Record<string, string> = {}
        parameters.forEach((parameter) => {
            const keyValue = parameter.split('=')
            const key = this.getKey(keyValue[0])
            parametersObject.key = keyValue[1]
        })
        return parametersObject
    }
        
    getKey = (key: string) => {
        return `${key}`
    }

    setUser = (user: string, email: string) => {
        if (this.user === '' || this.email === '') {
            this.user = user;
            this.email = email; 
        }   
    }
}      